
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Modal,
    Avatar,
    Typography,
    Input,
    Tag
  } from "antd";
  import moment from "moment"
  import {useEffect, useState} from 'react';
  import ModalAdd from "../components/modals/leave"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined, DeleteOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
  
  import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
        title: "APPLIED AT",
        dataIndex: "date",
        key: "date",
        width: "7%",
    },
    {
        title: "USER",
        dataIndex: "user",
        key: "user",
        width: "20%",
    },
    {
      title: "START",
      dataIndex: "start",
      key: "start",
      width: "5%",
    },
    {
      title: "END",
      dataIndex: "end",
      key: "end",
      width: "5%",
    },
    {
      title: "DAYS TAKEN",
      dataIndex: "days",
      key: "days",
      width: "5%",
    },
    {
      title: "ACTUAL DAYS",
      dataIndex: "effectiveDaysTaken",
      key: "effectiveDaysTaken",
      width: "5%",
    },
    {
      title: "REASON",
      dataIndex: "reason",
      key: "reason",
      width: "30%",
    },
    {
      title: "REVOKED AT",
      dataIndex: "revokedAt",
      key: "revokedAt",
      width: "15%",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "5%",
    },
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])
  
    useEffect(()=>{
      setPermissions(permissionsprop);
    })
  
    useEffect(() => {
      refresh();
    }, [permissions]);

    useEffect(() => {
      refresh();
    }, [reportPeriod]);
  
    const refresh = () => {
      const url = process.env.REACT_APP_API_URI+`/leaves?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`;
      axios.get(url, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data);
        setEmployees(tableDateFormater(data.data));
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
    const tableDateFormater = (data) =>{
      var arrayList = [];
        data.forEach(element => {
          arrayList.push(
            {
              key: element.id,
              date : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.date}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              user : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.applicant?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              start : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.start}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              end : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.end}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              days : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.days}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              effectiveDaysTaken : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.effectiveDaysTaken}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              reason : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.reason}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              revokedAt : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.revokedAt}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              action: (
                <>
                  {/* {
                    permissions.some(x=>x.permission == "update_leave")?
                    <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                    :
                    ""
                  } */}
                  {
                    permissions.some(x=>(x.permission == "approve_leave_1" && element.status == "pending")) || 
                    permissions.some( x => x.permission == "approve_leave_2" && element.status == "approve_1")?

                    <Button onClick={()=>{
                      
                      Modal.confirm({
                        title  : "Are you sure, you want to Approve this Leave",
                        onCancel : ()=>console.log('cancelled'),
                        onOk : ()=>approve(element.id)
                        
                      })
                    }} type="primary" className="ant-btn-sm m-5" >Approve</Button>
                    :
                    ""
                  }

                  {
                    permissions.some(x=>(x.permission == "revoke_leave" && element.status == "approved"))?

                    <Button onClick={()=>{
                      
                      Modal.confirm({
                        title  : "Are you sure, you want to Revoke this Leave",
                        onCancel : ()=>console.log('cancelled'),
                        onOk : ()=>revoke(element.id)
                        
                      })
                    }} type="danger" className="ant-btn-sm m-5" >Revoke</Button>
                    :
                    ""
                  }
                  
                  {
                    permissions.some(x=>(x.permission == "decline_leave" && element.status == 'pending')) || (permissions.some(x=>(x.permission == "approve_leave_2" )) && permissions.some(x=>(x.permission == "decline_leave" && element.status == 'approve_1')))?
                    <Button onClick={()=>{
                      Modal.confirm({
                        title  : "Are you sure, you want to Decline this Leave",
                        onCancel : ()=>console.log('cancelled'),
                        onOk : ()=>{
                          setEmployee(element);
                          setOpenEmployeeModal(true)
                        }
                      })
                      
                    }} type="dashed" className="ant-btn-sm m-5" danger>Decline</Button>
                    :
                    ""
                  }
                  {
                    permissions.some(x=>x.permission == "delete_leave")?
                    <Button onClick={()=>{Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteEmployee(element.id)
                    })}} type="" className="ant-btn-sm m-5" danger><DeleteOutlined/>Delete</Button> 
                    :
                    ""
                  }
                </>
              ),  
              status: (
                <> 
                    
                    <Tag color={
                        element.status== 'approved'?
                        "green":
                        element.status== 'pending'?
                        "#f50"
                        :
                        "red"
                    } >
                        {element.status.toUpperCase()}
                    </Tag>
                    
                </>
              )
            }
          )
        });
        return arrayList;
    }
  
    const search = (value) =>{
      const filteredData = data.filter(x =>
          x?.applicant?.name?.toUpperCase().includes(value.toUpperCase()) ||
          String(x.reason).toUpperCase().includes(value.toUpperCase()) ||
          x.status.toUpperCase().includes(value.toUpperCase()) ||
          x.date.toUpperCase().includes(value.toUpperCase())
        );
        setEmployees(tableDateFormater(filteredData));
    }
    const deleteEmployee = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/leaves/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error : error})
      })
    }

    const approve = (id) =>{
        axios.put(process.env.REACT_APP_API_URI+`/leaves/approve/${id}`,{}, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          refresh();
        }).catch(error =>{
          requestError({error : error})
        })
      }
    const revoke = (id) =>{
        axios.put(process.env.REACT_APP_API_URI+`/leaves/revoke/${id}`,{}, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          refresh();
        }).catch(error =>{
          requestError({error : error})
        })
      }
    const decline = (id) =>{
        axios.put(process.env.REACT_APP_API_URI+`/leaves/decline/${id}`,{}, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          refresh();
        }).catch(error =>{
          requestError({error : error})
        })
      }
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="LEAVE APPLICATIONS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event)=> search(event.target.value)} placeholder="Search employee's name" />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/leaves/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                    </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <ModalAdd  permissions={permissions} refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
        {/* <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/> */}
      </>
    );
  }
  
  export default EmployeesTable;
  