
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Modal,
    Input,
    Tag
  } from "antd";
  
  import {useEffect, useState} from 'react';
  import ModalAdd from "../components/modals/Coolers"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
import { requestError } from "../controllers/global";
  
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
    {
      title: "SERIAL NUMBER",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "MODEL",
      dataIndex: "model",
      key: "model",
    },
    
    {
      title: "OUTLET",
      dataIndex: "outlet",
      key: "outlet",
      width: "32%",
    },
    {
        title: "COMPANY",
        dataIndex: "company",
        key: "company",
        width: "32%",
    },
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([]);
    const [data, setData] = useState([]);
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [permissions, setPermissions] = useState([]);
  
    useEffect(()=>{
      setPermissions(permissionsprop);
    })
  
    useEffect(() => {
      refresh();
    }, [permissions]);
  
    const refresh = () => {
      const url = process.env.REACT_APP_API_URI+"/coolers/";

      axios.get(url, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setEmployees(tableDateFormater(data.data));
        setData(data.data)
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
const search = (value) =>{
  console.log(value)
  const filteredData = data.filter(x =>
      x.name.toUpperCase().includes(value.toUpperCase()) ||
      x.serial_number.toUpperCase().includes(value.toUpperCase()) ||
      x.model.toUpperCase().includes(value.toUpperCase()) ||
      x.outlet?.name.toUpperCase().includes(value.toUpperCase()) ||
      x.company?.name.toUpperCase().includes(value.toUpperCase())
    );
    setEmployees(tableDateFormater(filteredData));
}

  
const tableDateFormater = (data) =>{
  var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: "1",
            name: (
              <>
                  <div className="avatar-info">
                    <p>{element.name}</p>
                  </div>
              </>
            ),
            serial: (
              <>
                  <div className="avatar-info">
                    <p>{element.serial_number}</p>
                  </div>
              </>
            ),
            model: (
              <>
                  <div className="avatar-info">
                    <p>{element.model}</p>
                  </div>
              </>
            ),
            
            outlet : (
              <>
                  <div className="avatar-info">
                    <p>{element?.outlet?.name}</p>
                  </div>
              </>
            ),
            company: (
              <>
                <div className="author-info">
                  <p> {element.company?.name}</p>
                </div>
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "update_cooler")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "delete_cooler")?
                  <Button onClick={()=>{Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteEmployee(element.id)
                    })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      return arrayList;
}
    const deleteEmployee = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/coolers/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="COOLERS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <Input onChange={(event)=>search(event.target.value)} placeholder="Search name or serial no." />
                      </Col>
                      <Col>
                        <Button onClick={()=>{setAction(`create`);setOpenEmployeeModal(true); console.log("open")}} >ADD COOLERS</Button>
                        <a href={process.env.REACT_APP_API_URI+`/coolers/excel?token=${localStorage.getItem('token')}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <ModalAdd refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      </>
    );
  }
  
  export default EmployeesTable;
  