
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Tag,
  Modal,
  Image
} from "antd";
import moment from "moment"
import {useEffect, useState} from 'react';
import ModalAdd from "../components/modals/orders"
import axios from 'axios';
import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
import face2 from "../assets/images/face-2.jpg";

import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";

const { Title } = Typography;

// table code start
const columns = [
  {
    title: "Facing Image",
    dataIndex: "image",
    key: "image",
    width: "15%",
  },
  {
      title: "Reported On",
      dataIndex: "date",
      key: "date",
      width: "7%",
  },
  {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
      width: "26%",
  },
  {
    title: "OWN FACINGS",
    dataIndex: "own_facings",
    key: "own_facings",
    width: "5%",
  },
  
  {
    title: "COMPETITOR FACINGS",
    dataIndex: "competitor_facings",
    key: "competitor_facings",
    width: "7%",
  },
  {
    title: "TOTAL",
    dataIndex: "total_facings",
    key: "total_facings",
    width: "7%",
  },
  {
    title: "SOVI",
    dataIndex: "solvi",
    key: "solvi",
    width: "5%",
  },
  
  {
    title: "COMPANY",
    dataIndex: "company",
    key: "company",
    width: "18%",
  },
  
  {
    title: "OUTLET",
    dataIndex: "outlet",
    key: "outlet",
    width: "18%",
  }
  ,
  {
    title: "ACTION",
    key: "status",
    dataIndex: "action",
    width: "7%",
  }
];
function EmployeesTable({permissionsprop}) {
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
  const [employees, setEmployees] = useState([])
  const [data, setData] = useState([])
  const [employee, setEmployee] = useState(null);
  const [action, setAction] = useState('create');
  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
  const [permissions, setPermissions] = useState([]);
  const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])


  useEffect(()=>{
    setPermissions(permissionsprop);
  })

  useEffect(() => {
    refresh();
  }, [permissions]);

  const FacingImage = ({url}) =>{
    const [visible, setVisible] = useState(false);
    return  <>
    <Image
      preview={{
        visible: false,
      }}
      width={70}
      height={70}
      src={url}
      onClick={() => setVisible(true)}
    />
    <div
      style={{
        display: 'none',
      }}
    >
      <Image.PreviewGroup
        preview={{
          visible,
          onVisibleChange: (vis) => setVisible(vis),
        }}
      >
        <Image src={url} />
        <Image src={url} />
        <Image src={url} />
      </Image.PreviewGroup>
    </div>
  </>
  }

  useEffect(()=>{
    refresh();
  }, [reportPeriod])

  const refresh = () => {
    const url = process.env.REACT_APP_API_URI+`/facings?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`;
    axios.get(url, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setData(data.data);
      setEmployees(tableDateFormater(data.data));
    }).catch(error =>{
      requestError({error : error})
    })
  }

  
  const tableDateFormater = (data) =>{
    var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: element.id,
            image : (
              <>
                <Avatar.Group>
                  <FacingImage url={element?.image} />
                </Avatar.Group>{" "}
              </>
            ),
            date : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.date}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            product : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.product?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            own_facings : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.own_facings}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            competitor_facings : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.competitor_facings}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            total_facings : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.total_facings}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            solvi : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.solvi}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            outlet: (
              <>
                <div className="author-info">
                  <p> {element?.outlet?.name}</p>
                </div>
              </>
            ),
            company: (
              <>
                <div className="author-info">
                  <p> {element?.company?.name}</p>
                </div>
              </>
            ),
            action: (
              <>
                {/* {
                  permissions.some(x=>x.permission == "update_facing")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                  :
                  ""
                } */}
                {
                  permissions.some(x=>x.permission == "delete_facing")?
                  <Button onClick={()=>{Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteEmployee(element.id)
                    })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            )
          }
        )
      });
      return arrayList;
  }

  const search = (value) =>{
    const filteredData = data.filter(x =>
        x?.product?.name.toUpperCase().includes(value.toUpperCase()) ||
        x?.outlet?.name.toUpperCase().includes(value.toUpperCase()) ||
        x?.company?.name.toUpperCase().includes(value.toUpperCase()) 
      );
      setEmployees(tableDateFormater(filteredData));
  }


  const deleteEmployee = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/facings/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      requestError({error : error})
    })
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="FACINGS"
              extra={
                <>
                  <Row>
                    <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                    <Col>
                      <Input onChange={(event)=>search(event.target.value)} placeholder="Search Product Name..." />
                    </Col>
                    <Col>
                        <a href={process.env.REACT_APP_API_URI+`/facings/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                    </Row>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={employees}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {/* <ModalAdd refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/> */}
    </>
  );
}

export default EmployeesTable;
