
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Modal,
  Tag
} from "antd";

import {useEffect, useState} from 'react';
import ModalAdd from "../components/modals/prodcuts"
import axios from 'axios';
import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
import face2 from "../assets/images/face-2.jpg";

import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";

const { Title } = Typography;

// table code start
const columns = [
  {
    title: "PRODUCT NAME",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "CATEGORY",
    dataIndex: "productcategory",
    key: "productcategory",
    width: "32%",
  },
  {
    title: "PRODUCT COMPANY",
    dataIndex: "company",
    key: "company",
  },

  {
    title: "ACTION",
    key: "status",
    dataIndex: "action",
    width: "7%",
  }
];

function EmployeesTable({permissionsprop}) {
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
  const [employees, setEmployees] = useState([])
  const [data, setData] = useState([])
  const [employee, setEmployee] = useState(null);
  const [action, setAction] = useState('create');
  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
  const [permissions, setPermissions] = useState([]);

  useEffect(()=>{
    setPermissions(permissionsprop);
  })

  useEffect(() => {
    refresh();
  }, [permissions])

  const refresh = () => {
    axios.get(process.env.REACT_APP_API_URI+"/products/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setData(data.data)
      setEmployees(tableDateFormater(data.data));
    }).catch(error =>{
      console.log(error)
      requestError({error : error})
    })
  }

  const tableDateFormater = (data) =>{
    var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: element.id,
            name: (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            productcategory : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.productcategory?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            company: (
              <>
                <div className="author-info">
                  <p> {element.company?.name}</p>
                </div>
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "update_product")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "delete_product")?
                  <Button onClick={()=>{Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteEmployee(element.id)
                    })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      return arrayList;
  }

  const search = (value) =>{
    const filteredData = data.filter(x =>
        x.name.toUpperCase().includes(value.toUpperCase()) ||
        x.productcategory?.name.toUpperCase().includes(value.toUpperCase()) ||
        x.company?.name.toUpperCase().includes(value.toUpperCase()) 
      );
      setEmployees(tableDateFormater(filteredData));
  }

  const deleteEmployee = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/products/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      requestError({error : error}) 
    })
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="PRODUCTS"
              extra={
                <>
                  <Row>
                    <Col>
                      <Input onChange={(event) => search(event.target.value)} placeholder="Search name" />
                    </Col>
                    <Col>
                      <Button onClick={()=>{setAction(`create`);setOpenEmployeeModal(true); console.log("open")}} >ADD PRODUCTS</Button>
                        <a href={process.env.REACT_APP_API_URI+`/products/excel?token=${localStorage.getItem('token')}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                    </Col>
                    </Row>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={employees}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalAdd refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/>
    </>
  );
}

export default EmployeesTable;
