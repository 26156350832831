
import { Switch, Route, Redirect, } from "react-router-dom";
import Home from "./pages/Home";
import Employess from "./pages/Employess";
import Products from "./pages/products"
import Orders from "./pages/orders";
import Outlets from "./pages/Outlets";
import Companies from "./pages/Companies";
import Coolers from "./pages/Coolers";
import CoolerFaults from "./pages/CoolerFaults";
import Sales from "./pages/sales";
import Stocks from "./pages/stocks";
import OutletPromotions from "./pages/OutletPromotions";
import Expiries from "./pages/Expiries";
import Facings from "./pages/Facings";
import LoginReport from "./pages/LoginReport";
import Leaves from "./pages/Leaves";
import Activities from "./pages/Activities";
import Surveys from "./pages/Surveys";
import Promotions from "./pages/Promotions";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Welcome";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import Password from "./pages/password";
import Welcome from "./pages/Welcome";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/incidentTable.css"
import "./assets/styles/responsive.css";
import 'animate.css';
import {useEffect, useState} from 'react'
import axios from 'axios'
import Measurements from "./pages/Measurements";
import StockTransfers from "./pages/StockTransfers";
import StockReturns from "./pages/StockReturns";
// import 'bootstrap/dist/css/bootstrap.css';

function App() {
  const [permissions, setPermissions] = useState([])
  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URI+"/auth/my/permissions", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setPermissions(data.data);
    }).catch(error =>{
      console.log(error)
    })
  },[])
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route exact path="/password" render={(props) => <Password token={localStorage.getItem("token")}/>}   />
        <Main permissionsprop={permissions}>

          <Route exact path="/welcome"  render={(props) =><Welcome permissionsprop={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/dashboard"  render={(props) =><Home permissionsprop={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/employees" render={(props) => <Employess permissionsprop = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/products" render={(props) => <Products  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/orders" render={(props) => <Orders  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/outlets" render={(props) => <Outlets  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/companies" render={(props) => <Companies permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/coolers" render={(props) => <Coolers permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/coolerfaults" render={(props) => <CoolerFaults permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/sales" render={(props) => <Sales permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/expiries" render={(props) => <Expiries permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/facings" render={(props) => <Facings permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/outletpromotions" render={(props) => <OutletPromotions permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/loginreport" render={(props) => <LoginReport permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/leaves" render={(props) => <Leaves permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/surveys" render={(props) => <Surveys permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/stocks" render={(props) => <Stocks permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/activities" render={(props) => <Activities permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/promotions" render={(props) => <Promotions permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/rtl" render={(props) => <Rtl permissionsprop = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/profile" render={(props) => <Profile  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/measurements" render={(props) => <Measurements  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/stocktransfers" render={(props) => <StockTransfers  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/stockreturns" render={(props) => <StockReturns  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
        
        </Main>
      </Switch>
    </div>
  );
}

export default App;
