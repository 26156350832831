import { Button, Modal, Input, Form, Select, message, Card } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';
import { DeleteOutlined } from '@ant-design/icons';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState("")

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

  useEffect(() => {
    setComments(employee?.coolerfault_comments || [])
  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setComment([])
  }

  const submit = (event) =>{
      const data = {
        comment : comment,
        coolerfault_id : employee?.id
      }

    axios.post(process.env.REACT_APP_API_URI+"/coolerfaults/comment", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      setComments([...comments, res.data.data])
      message.success(res.data.res)
      setLoading(false);
      refresh();
    }).catch(error=>{
      setLoading(false);
      requestError({error : error})
    })
  }

  const deleteComment = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/coolerfaults/comment/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      setComments([...comments.filter(x => x.id != id)])
      message.success(res.data.res)
      setLoading(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;

  return (
    <>
    
      <Modal 
      title={`CCOLER FAULT ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Close
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
          Submit
        </Button>     
      ]}
      >
      {
        comments.map(data =><>
          <Card style={{margin : 5, }} >
            <div style={{float : "right"}}>
              <Button onClick={()=>deleteComment(data.id)}  className="ant-btn-sm m-5"><DeleteOutlined title='Delete'  style={{color:"red"}}/>Delete</Button>
            </div>
            <div>{data?.comment}</div>
          </Card>
        </>)
      }
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "Coment on the cooler fault"
        rules={[
            { required: true, message: "Please input your comment!" },
        ]}>
          <Input value={comment} onChange={(event)=>setComment(event.target.value)} name = "comment" placeholder="Place your cooler fault repair comment here..." />
        </Form.Item>

      </Form>
      </Modal>
    </>
  );
};

export default App;