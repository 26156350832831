
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Modal,
    Tag
  } from "antd";
  import moment from "moment"
  import {useEffect, useState} from 'react';
  import ModalAdd from "../components/modals/orders"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
  
  import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
        title: "DATE",
        dataIndex: "date",
        key: "date",
        width: "7%",
    },
    {
        title: "USER",
        dataIndex: "user",
        key: "user",
        width: "32%",
    },
    {
        title: "USERNAME",
        dataIndex: "username",
        key: "user",
        width: "15%",
    },
    {
        title: "LOGIN TIME",
        dataIndex: "logintime",
        key: "logintime",
        width: "15%",
    },
    {
      title: "LOGOUT TIME",
      dataIndex: "logouttime",
      key: "logouttime",
      width: "15%",
    },
    {
      title: "OUTLET",
      dataIndex: "outlet",
      key: "outlet",
      width: "20%",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
      width: "20%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([]);
    const [action, setAction] = useState('create');
    const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])

  
    useEffect(()=>{
      setPermissions(permissionsprop);
    })
  
    useEffect(() => {
      refresh();
    }, [permissions]);

    useEffect(() => {
      refresh();
    }, [reportPeriod]);
  
    const refresh = () => {
      const url = process.env.REACT_APP_API_URI+`/loginreports?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`;

      axios.get(url, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        tableDataFormatter(data.data);
        setData(data.data);
      }).catch(error =>{
        requestError({error : error})
      })
    }
    const tableDataFormatter = (list) => {
      console.log(list)
      var arrayList = [];
      list.forEach(element => {
        arrayList.push(
          {
            key: element.id,
            date : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.date}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            user : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.user?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            username : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.user?.username}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            logintime : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element.logintime}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            logouttime : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.logouttime}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            outlet: (
              <>
                <div className="author-info">
                  <p> {element?.outlet?.name}</p>
                </div>
              </>
            ),
            company: (
              <>
                <div className="author-info">
                  <p> {element?.company?.name}</p>
                </div>
              </>
            )
          }
        )
      });
      setEmployees(arrayList);
    }

  
    const search = (value) =>{
      const filteredData = data.filter(x => 
          x?.outlet?.name.toUpperCase().includes(value.toUpperCase()) ||
          x?.user?.name.toUpperCase().includes(value.toUpperCase()) ||
          x?.date.toUpperCase().includes(value.toUpperCase()) ||
          x?.user?.username.toUpperCase().includes(value.toUpperCase()) ||
          x?.company?.name.toUpperCase().includes(value.toUpperCase()) 
        );
      
      tableDataFormatter(filteredData);
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="LOGIN REPORT"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event) => search(event.target.value)} placeholder="Type here to search..." />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/loginreports/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/users/attendance?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >Attendance</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        {/* <ModalAdd refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
        <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/> */}
      </>
    );
  }
  
  export default EmployeesTable;
  