
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  message,
  Switch,
} from "antd";

import {requestError} from '../controllers/global';
import logo from "../assets/images/logo.png"
const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default class SignIn extends Component {
  constructor(props){
    super(props)
    this.state = {
      loding : false,
      termsAndConditionsAccepted : false
    }
  }

  valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }

  submit = (event) =>{
      this.setState({loading : true});
      const data = {
        username : this.state.username,
        password : this.state.password,
      } 
      
    axios.post(process.env.REACT_APP_API_URI+"/auth/login", data).then(res=>{
      this.setState({loading : false});

      message.success(res.data.data.res);
      localStorage.setItem("token", res.data.data.token);
      localStorage.setItem("name", res.data.data.name);
      localStorage.setItem("accesslevel", res.data.data.accesslevel);
      if(res.data.data.accesslevel_id == 1){
        window.location = "/dashboard"
      }else{
        window.location = "/welcome"
      }
    }).catch(error=>{
      this.setState({loading : false});
      requestError({error : error})
    })
  }
  render() {
    return (
      <>
        <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1">
                  <Link to="/dashboard">
                    <span> WINDS CORPORATE SERVICES ADMINISTRATION PLATFORM.</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
            
          </Header>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <div style={{textAlign:"center", marginBottom:"4%"}}>
                  <img  height={100} width={120} alt="Winds Corporate Services" src={logo}></img>
                </div>
                <Form
                  layout="vertical"
                  className="row-col"
                  onSubmit = {() =>{this.submit()}}
                >
                  <Form.Item
                    className="username"
                    label="username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input onChange={this.valuePicker} name="username" placeholder="username" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input onChange={this.valuePicker} name = "password" type="password" placeholder="Password" />
                  </Form.Item>

                  <Form.Item className= "m-0">
                    <Button
                    loading={this.state.loading}
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      onClick = {() =>{this.submit()}}
                    >
                      SIGN IN
                    </Button>
                    
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Content>
          <Footer>
            
            <div style={{alignItems:"center"}} >
              <p className="copyright" >
              &emsp; &emsp; © 2023 <a href="#pablo">Winds Corporate Services</a>.{" "}
              </p>
            </div>
            
          </Footer>
        </Layout>
      </>
    );
  }
}
