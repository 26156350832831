import { Button, Modal, Input, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company_id, setCompanyId] = useState(null)
  const [accessLevels, setAccessLevels] = useState([]);
  const [managers, setManagers] = useState([]);
  const [accesslevel_id, setAccessLevelId] = useState(null)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [username, setUsername] = useState("")
  const [employee_number, setEmployeeNumber] = useState("")
  const [available_holiday_leave_days, setAvailableHolidayLeaveDays] = useState(0);
  const [available_leave_days, setAvailableLeaveDays] = useState(0);
  const [password, setPassword] = useState("")
  const [address, setAddress] = useState("")
  const [employeeid, setEmployeeId] = useState(null)
  const [manager_id, setManagerId] = useState([])
  const [passwordResertLinkRequesting, setPasswordResertLinkRequesting] = useState(false)

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

    
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URI+"/accesslevels/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setAccessLevels(data.data.res);
    }).catch(error =>{
      // requestError({error : error})
    })

    axios.get(process.env.REACT_APP_API_URI+"/companies/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setCompanies(data.data.res);
    }).catch(error =>{
      // requestError({error : error})
    })

    axios.get(process.env.REACT_APP_API_URI+"/users/managers/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setManagers(data.data);
    }).catch(error =>{
      // requestError({error : error})
    })


    if(action== `edit`){
      clear();
      setName(employee?.name)
      setEmail(employee?.email)
      setUsername(employee?.username)
      setPhone(employee?.phone)
      setAddress(employee?.address)
      setAccessLevelId(employee?.accesslevel?.id)
      setManagerId(employee?.manager_id)
      setCompanyId(employee?.company_id)
      setAvailableHolidayLeaveDays(employee?.available_holiday_leave_days)
      setAvailableLeaveDays(employee?.available_leave_days)
      setEmployeeNumber(employee?.setEmployeeNumber)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setName("")
    setEmail("")
    setPhone('')
    setAddress("")
    setAccessLevelId("")
    setUsername("")
    setEmployeeNumber("")
    setAvailableHolidayLeaveDays(0)
    setAvailableLeaveDays(0)
    setManagerId(null)
    setCompanyId(null)
  }

  const submit = (event) =>{
      const data = {
        name : name,
        email : email,
        username : username,
        employee_number : employee_number,
        phone : phone,
        address : address,
        password : password,
        accesslevel_id : accesslevel_id,
        manager_id : manager_id,
        company_id : company_id,
        available_holiday_leave_days : available_holiday_leave_days,
        available_leave_days
      } 

    axios.post(process.env.REACT_APP_API_URI+"/users/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setEmployeeId(res.data.data.id)
      setOpenPictureUploadModal(false);
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
      setLoading(false)
    })
  }
  const edit = (event) =>{
    const data = {
      name : name,
      username : username,
      employee_number : employee_number,
      phone : phone,
      address : address,
      accesslevel_id : accesslevel_id,
      manager_id : manager_id,
      company_id : company_id,
      available_holiday_leave_days : available_holiday_leave_days,
      available_leave_days
    } 
    axios.put(process.env.REACT_APP_API_URI+`/users/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
      setLoading(false)
    })
  }

  const passwordResetLink = (id) =>{
    setPasswordResertLinkRequesting(true)
    console.log(`Beare ${localStorage.getItem("token")}`)
    axios.get(process.env.REACT_APP_API_URI+`/auth/password/reset/link/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(res =>{
      setPasswordResertLinkRequesting(false)
      message.success(res.data.res)
    }).catch(error =>{
      setPasswordResertLinkRequesting(false)
      message.error('Failed to request a password reset link') 
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;

  return (
    <>
    
      <Modal 
      title={`EMPLOYEE DETAILS ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
            Submit
          </Button>
          :
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "Full Name"
        rules={[
            { required: true, message: "Please input your name!" },
        ]}>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Name e.g Joe Doe" />
        </Form.Item>

        <Form.Item
          label = "email"
          rules={[
              { required: true, message: "Please input your email!" },
          ]}
          >
          <Input value={email} onChange={(event)=>setEmail(event.target.value)} name = "email" placeholder="Email e.g exampla@test.com" />
        </Form.Item>

        <Form.Item
          label = "username"
          rules={[
              { required: true, message: "Please input your username!" },
          ]}
          >
          <Input value={username} onChange={(event)=>setUsername(event.target.value)} name = "username" placeholder="Username e.g testusername" />
        </Form.Item>

        <Form.Item
          label = "Employee Number"
          rules={[
              { required: true, message: "Please input your Employee Number!" },
          ]}
          >
          <Input value={employee_number} onChange={(event)=>setEmployeeNumber(event.target.value)} name = "employee_number" placeholder="Employee Number..." />
        </Form.Item>

        <Form.Item
          label = "Annual Leave Days"
          rules={[
              { required: true, message: "Annual Leave Days" },
          ]}
          >
          <Input value={available_leave_days} onChange={(event)=>setAvailableLeaveDays(event.target.value)} name = "available_holiday_leave_days" placeholder="Available Holiday Leave Day..." />
        </Form.Item>

        <Form.Item
          label = "Available Holiday Leave Days"
          rules={[
              { required: true, message: "Please input your Available Holiday Leave Day!" },
          ]}
          >
          <Input value={available_holiday_leave_days} onChange={(event)=>setAvailableHolidayLeaveDays(event.target.value)} name = "available_holiday_leave_days" placeholder="Available Holiday Leave Day..." />
        </Form.Item>


        <Form.Item
          label = "password"
          rules={[
              { required: true, message: "Please input your password!" },
          ]}
          >
            {
              action== `edit`?
              <Button loading ={passwordResertLinkRequesting} onClick={()=>passwordResetLink(employee?.id)}>Request Password Reset Link</Button>
              :
              <Input value={password} type={'password'} onChange={(event)=>setPassword(event.target.value)} name = "password" placeholder="" />
            }
        </Form.Item>

        <Form.Item
          label = "Phone Number"
          rules={[
              { required: true, message: "Please input your phone!" },
          ]}>
          <Input value={phone} onChange={(event)=>setPhone(event.target.value)} name="phone" placeholder="Phone Number e.g +64 990 008 9099" />
        </Form.Item>
        <Form.Item
          label = "Address"
          rules={[
              { required: true, message: "Please input your address!" },
          ]}>
          <Input value={address} onChange={(event)=>setAddress(event.target.value)} name = "address" placeholder="Address location e.g 173 NSW, Australia" />
        </Form.Item>
        <Form.Item
          label = "Select Access Level"
          rules={[
              { required: true, message: "Please selecte Access Level for the user!" },
          ]}
          >
            <Select
              showSearch
              onChange={(value)=>setAccessLevelId(value)}
              style={{
                // width: 200,
              }}
              value={accesslevel_id}
              placeholder="Select Access Level"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {
                accessLevels.map(item =>
                  <Option value={item.id}>{item.name}</Option>
                  )
              }
            </Select>
        </Form.Item>
        <Form.Item
          label = "Select Field Manager"
          rules={[
              { required: true, message: "Please select Field Manager for the user!" },
          ]}
          >
            <Select
              showSearch
              onChange={(value)=>setManagerId(value)}
              value={manager_id}
              placeholder="Select Field Manager"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value={null}>-- Select Field Manager ---</Option>
              {
                managers.map(item =>
                  <Option value={item.id}>{item.name}</Option>
                  )
              }
            </Select>
        </Form.Item>

        <Form.Item
        label = "Select Company"
        rules={[
            { required: true, message: "Please select Company for the product!" },
        ]}
        >
          <Select
            showSearch
            onChange={(value)=>setCompanyId(value)}
            style={{
              // width: 200,
            }}
            value={company_id}
            placeholder="Assign a company to this user"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          > 
            <Option value={null}>-- Select Company ---</Option>
            {
              companies.map(item =>
                <Option value={item.id}>{item.name}</Option>
                )
            }
          </Select>
          </Form.Item>
      </Form>
      </Modal>
      <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employeeid}/>
    </>
  );
};

export default App;