

import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import React, {Component,useEffect, useState} from "react"
const { Title, Paragraph } = Typography;

const LineChart = (props) =>{

  const [series, setSeries] = useState([]);
  const [line, setLineChart] = useState(lineChart);


  useEffect(()=>{
      console.log(props.data)
      var temp = lineChart.series;
      temp[0].data = props?.data;
      setLineChart({...line, options: {xaxis : {categories :  props?.days}}})
      setSeries(temp)
  }, [props]);

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Attendance Report for this Month</Title>
          <div className="lastweek">
            This is based on the login report records
          </div>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={line.options}
        series={series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}
export default LineChart;