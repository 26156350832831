

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import eChart from "./configs/eChart";
import React, {Component,useEffect, useState} from "react"
import moment from "moment";
const { Title, Paragraph } = Typography;

export default class EChart extends Component {
  constructor(props){
    super(props)
    this.state = {
      series : []
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.data != this.props.data){
      var temp = eChart.series;
      temp[0].data = this.props.data.incidents;
      temp[1].data = this.props.data.correctiveactions;
      this.setState({series : temp})
    }
  }
  render(){
    return (
      <>
        <div className="mb-4">
          <Title level={5}>{`Attendance Report`}</Title>
          <div className="lastweek">
            This have been generated from Login Report
          </div>
        </div>

        <div id="chart">
          <ReactApexChart
            className="bar-chart"
            options={eChart.options}
            series={this.state.series}
            height={300}
            type="bar"
          />
        </div>
        
      </>
    );
  }
}
