import { Button, Modal, Input, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [name, setName] = useState("")
  const [company_id, setCompanyId] = useState(null)
  const [product_category_id, setProductCategoryId] = useState(null)


  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

    
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URI+"/companies/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setCompanies(data.data.res);
    }).catch(error =>{
      requestError({error : error})
    })

    if(action== `edit`){
      clear();
      setName(employee?.name)
      setCompanyId(employee?.company_id)
      setProductCategoryId(employee?.product_category_id)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URI+`/productcategories/company/${company_id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setProductCategories(data.data);
    }).catch(error =>{
      requestError({error : error})
    })
  }, [company_id])


  const clear = () =>{
    setName("")
    setCompanyId(null)
    setProductCategoryId(null)
  }

  const submit = (event) =>{
      const data = {
        name : name,
        company_id : company_id,
        product_category_id : product_category_id
      }

    axios.post(process.env.REACT_APP_API_URI+"/products/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setLoading(false);
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    })
  }
  const edit = (event) =>{
    const data = {
      name : name,
      company_id : company_id,
      product_category_id : product_category_id
    } 
    axios.put(process.env.REACT_APP_API_URI+`/products/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      setLoading(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }
  return (
    <>
    
      <Modal 
      title={`PRODUCT DETAILS ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
            Submit
          </Button>
          :
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "Full Name"
        rules={[
            { required: true, message: "Please input your name!" },
        ]}>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Name e.g Joe Doe" />
        </Form.Item>
        
        <Form.Item
        label = "Select Company"
        rules={[
            { required: true, message: "Please selecte Company for the product!" },
        ]}
        >
          <Select
            showSearch
            onChange={(value)=>setCompanyId(value)}
            style={{
              // width: 200,
            }}
            value={company_id}
            placeholder="Select Company"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {
              companies.map(item =>
                <Option value={item.id}>{item.name}</Option>
                )
            }
          </Select>
        </Form.Item>
        
        <Form.Item
        label = "Select Product Category"
        rules={[
            { required: true, message: "Please select Product Category for the product!" },
        ]}
        >
          <Select
            showSearch
            onChange={(value)=>setProductCategoryId(value)}
            style={{
              // width: 200,
            }}
            value={product_category_id}
            placeholder="Select Product Category"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {
              productCategories.map(item =>
                <Option value={item.id}>{item.name}</Option>
                )
            }
          </Select>
        </Form.Item>
      </Form>
      </Modal>
    </>
  );
};

export default App;