
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Modal,
    Tag
  } from "antd";
  
  import moment from "moment"
  import {useEffect, useState} from 'react';
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
  
  import PictureUpload from '../components/modals/pictureupload';
  import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
        title: "CREATED BY",
        dataIndex: "creator",
        key: "creator",
    },
    {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "SOURCE OUTLET",
      dataIndex: "source_outlet",
      key: "source_outlet",
    },
    {
      title: "DESTINATION",
      dataIndex: "destination_outlet",
      key: "destination_outlet",
    },

    {
        title: "COMPANY",
        dataIndex: "company",
        key: "company",
      },
  
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  
  function StockTransfers({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])

  
    useEffect(()=>{
      setPermissions(permissionsprop);
    })
  
    useEffect(() => {
      refresh();
    }, [permissions])
  
  
    useEffect(() => {
      refresh();
    }, [reportPeriod])
  
    const refresh = () => {
      axios.get(process.env.REACT_APP_API_URI+`/stocktransfers?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data)
        setEmployees(tableDateFormater(data.data));
      }).catch(error =>{
        console.log(error)
        requestError({error : error})
      })
    }
  
    const tableDateFormater = (data) =>{
      var arrayList = [];
        data.forEach(element => {
          const quantity = element?.quantity/(element.measurement.quantity || 1)
          arrayList.push(
            {
              key: element.id,
              creator: (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.creator?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              product: (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.product?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              source_outlet: (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.source_outlet?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              destination_outlet: (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.destination_outlet?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              quantity: (
                <>
                  <div className="author-info">
                    <p> {quantity} {quantity > 1? `${element.measurement.name}s` : element.measurement.name}</p>
                  </div>
                </>
              ),
              company: (
                <>
                  <div className="author-info">
                    <p> {element?.company?.name}</p>
                  </div>
                </>
              ),
              action: (
                <>
                  {
                    permissions.some(x=>x.permission == "update_stock_transfer")?
                    <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                    :
                    ""
                  }
                  {
                    permissions.some(x=>x.permission == "delete_stock_transfer")?
                    <Button onClick={()=>{Modal.confirm({
                        title  : "Are you sure, you want to delete",
                        onCancel : ()=>console.log('cancelled'),
                        onOk : ()=>deletestock_transfer(element.id)
                      })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                    :
                    ""
                  }
                </>
              ),
            }
          )
        });
        return arrayList;
    }
  
    const search = (value) =>{
      const filteredData = data.filter(x =>
          x.name.toUpperCase().includes(value.toUpperCase()) ||
          x.company.name.toUpperCase().includes(value.toUpperCase()) 
        );
        setEmployees(tableDateFormater(filteredData));
    }
  
    const deletestock_transfer = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/stocktransfers/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error : error}) 
      })
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="STOCK TRANSFERS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event) => search(event.target.value)} placeholder="Search stock transfer record" />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/stocktransfers/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/>
      </>
    );
  }
  
  export default StockTransfers;
  