import { Upload, message, Modal, Button } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState } from 'react';
const App = ({show, refresh ,setOpenPictureUploadModal, id}) => {
    
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [openned, setOpen] = useState(false);

  if(show != openned){
    setOpen(show);
  }
  
  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('doc', file);
    });
    setUploading(true);
    fetch(`http://localhost:40791/employees/upload/profile?employeeid=${id}`, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        res = res.json();
        console.log(res);
    })
      .then(() => {
        setFileList([]);
        message.success('upload successfully.');
        refresh();
        setOpenPictureUploadModal(false);
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  
  return (
    <>
         <Modal
            title = {`UPLOAD EMPLOYEE PROFILE PICTURE? ID ${id}`}
            visible = {openned}
            onCancel = {()=>setOpenPictureUploadModal(false)}
            footer = {[
                <Button key="" type='danger' style={{color:"red", backgroundColor:"white"}}  onClick={()=>{setOpenPictureUploadModal(false)}}>
                    No, Exit
                </Button>,
                <Button
                type="primary"
                onClick={()=>handleUpload()}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{
                marginTop: 16,
                }}
                >
                    {uploading ? 'Uploading' : 'Start Upload'}
                </Button>,
            ]}
            >
            <ImgCrop  modalTitle={`CROP YOUR PROFILE PICTURE`} modalOk={`Done Cropping`} rotate>
            <Upload
                name='doc' 
                listType="picture-card"
                fileList={fileList}
                // onChange={onChange}
                // onPreview={onPreview}
                {...props}
            >
                {fileList.length < 1 && '+ Upload'}
            </Upload>
            </ImgCrop>
        </Modal>    
    </>
  
  );
};
export default App;