import { Button, Modal, Input, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [name, setName] = useState("")
  const [company_id, setCompanyId] = useState(null)


  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

    
  useEffect(() => {

    if(action== `edit`){
      clear();
      setName(employee?.name)
      setQuantity(employee?.quantity)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setName("")
    setQuantity("")
  }

  const submit = (event) =>{
      const data = {
        name : name,
        quantity : quantity
      }

    axios.post(process.env.REACT_APP_API_URI+"/measurements/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
        setLoading(false);
    })
  }
  const edit = (event) =>{
    const data = {
      name : name,
      quantity : quantity
    } 
    axios.put(process.env.REACT_APP_API_URI+`/measurements/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
        setLoading(false);
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }
  return (
    <>
    
      <Modal 
      title={`MEASUREMENTS DETAILS ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
            Submit
          </Button>
          :
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "Unit of Measurement"
        rules={[
            { required: true, message: "Please enter Unit of Measurement!" },
        ]}>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Name e.g Joe Doe" />
        </Form.Item>
        
        <Form.Item
        label = "Quantity in this measurement"
        rules={[
            { required: true, message: "Please enter Quantity in this measurement!" },
        ]}
        >
            <Input value={quantity} onChange={(event)=>setQuantity(event.target.value)} name = "" placeholder="Quantity contained in this unit of measurement" />
        </Form.Item>
      </Form>
      </Modal>
    </>
  );
};

export default App;