
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Modal,
    Tag
  } from "antd";
  
  import {useEffect, useState} from 'react';
  import EmployeeModal from "../components/modals/outlets"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
  
  import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
    {
      title: "GPS LOCATION",
      dataIndex: "gps",
      key: "gps",
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      width: "7%",
    },
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
    const [permissions, setPermissions] = useState([]);
  
    useEffect(()=>{
      setPermissions(permissionsprop);
    })
  
    useEffect(() => {
      refresh();
    }, [permissions]);
  
    const refresh = () => {
      axios.get(process.env.REACT_APP_API_URI+"/outlets/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data.res);
        setEmployees(tableDateFormater(data.data.res))
      }).catch(error =>{
        requestError({error : error})
      })
    }

    const tableDateFormater = (data) =>{
      var arrayList = [];
        data.forEach(element => {
          arrayList.push(
            {
              key: "1",
              name: (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              gps: (
                <>
                  <div className="author-info">
                    <p> long: {element.longitude}, lat: {element.latitude}</p>
                  </div>
                </>
              ),
              status: (
                <>
                  {
                    element.status?
                    <Tag color={"green"} key={`Active`}>
                      {`Active`.toUpperCase()}
                    </Tag>
                    :
                    <Tag color={"red"} key={`Active`}>
                      {`Active`.toUpperCase()}
                    </Tag>
                  }
                </>
              ),
              action: (
                <>
                  {
                    permissions.some(x=>x.permission == "update_outlet")?
                    <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                    :
                    ""
                  }
                  {
                    permissions.some(x=>x.permission == "delete_outlet")?
                    <Button onClick={()=>{
                      Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteEmployee(element.id)
                    })
                  }} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                    :
                    ""
                  }
                </>
              ),
            }
          )
        });
        return arrayList;
    }

    const search = (value) =>{
      console.log(value)
      const filteredData = data.filter(x =>
          x.name.toUpperCase().includes(value.toUpperCase()) 
        );
        setEmployees(tableDateFormater(filteredData));
    }
  
    const deleteEmployee = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/outlets/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="OUTLETS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <Input onChange={(event)=>search(event.target.value)} placeholder="Seach Outlets" />
                      </Col>
                      <Col>
                        <Button onClick={()=>{setAction(`create`);setOpenEmployeeModal(true); console.log("open")}} >ADD OUTLET</Button>
                        <a href={process.env.REACT_APP_API_URI+`/outlets/excel?token=${localStorage.getItem('token')}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <EmployeeModal refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
        <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/>
      </>
    );
  }
  
  export default EmployeesTable;
  