import { Button, Modal, Form, Input, Select, Row, Col, DatePicker, TimePicker, List } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/global';
const { TextArea } = Input;


const App = ({show, setOpenAccessLevelModal, showDrawer, action, accessLevelId }) => {
  
  const { Option } = Select;
  const [permissions, setPermissions] = useState([])
  const [name, setName] = useState("")
  const [permission_ids, setPermissionIds] = useState([])


  
  const init = () => {
    
    axios.get(process.env.REACT_APP_API_URI+"/permissions/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      var data_list = [];
      data.data.res.forEach(element =>{
        data_list.push({
          label : element.permission,
          value : element.id
        })
      })

      axios.get(process.env.REACT_APP_API_URI+`/accesslevels/${accessLevelId}/permissionsids`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
            setName(data.data.res.name)
            setPermissionIds(data.data.res.permissionsids);
          }).catch(error =>{
            console.log(error)
          })

      setPermissions(data_list);
    }).catch(error =>{
      console.log(error)
    })

    

  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show);
    init();
  }
  useEffect(() => {
    clear();
    // init()
  }, [show]);

  const clear = () =>{
    setName("");
    setPermissionIds([]);
  }


  const submit = (event) =>{
    const data = {
      name : name,
      permissions : permission_ids
    } 
    axios.post(process.env.REACT_APP_API_URI+"/accesslevels/accesslevelpermissions/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      console.log(res)
      showDrawer();
      setLoading(false);
      setOpenAccessLevelModal(false);
    }).catch(error=>{
      requestError({error : error})
    })
  }

  const edit = (event) =>{
    var data = [];
    permission_ids.forEach(element => {
      data.push({
        accesslevel_id : accessLevelId,
        permission_id : element
      } )
    });
    axios.put(process.env.REACT_APP_API_URI+"/accesslevels/accesslevelpermissions/", {name : name, permissions : data} , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      setOpenAccessLevelModal(false);
      showDrawer();
      setLoading(false)
    }).catch(error=>{
      setLoading(false)
      requestError({error : error})
    })
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
    <Form
        layout="vertical"
        className="row-col"
      >
      <Modal 
      title="ACCESS LEVEL" 
      width={"60%"}
      visible={isModalVisible} 
      onOk={()=>setOpenAccessLevelModal(false)} 
      onCancel={()=>setOpenAccessLevelModal(false)}
      footer={[
        <Button key="submit" type=""  onClick={()=>{setOpenAccessLevelModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
        <Button key="submit" type="primary" loading={loading} onClick={()=>{submit();setLoading(true)}}>
            Submit
        </Button>
        :
        <Button key="submit" type="primary" loading={loading} onClick={()=>{edit();setLoading(true)}}>
            Save
        </Button>
      ]}
      >
        <Form.Item>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Type Access Level... " />
        </Form.Item>
        <Form.Item>
          <Row>
            <Select
                showSearch
                mode="multiple"
                onChange={(value)=>setPermissionIds(value)}
                value={permission_ids}
                placeholder="Select Field Manager"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.includes(input)}
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {
                  permissions.map(item =>
                    <Option value={item.value}>{item.label}</Option>
                    )
                }
              </Select>
          </Row>
        </Form.Item>
        
      </Modal>
      </Form>
    </>
  );
};

export default App;