import {Table, Avatar, Typography, Tag, Button, Modal, Input, Form, Select, message, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/global';
import { EyeOutlined } from '@ant-design/icons';
import ModalAdd from "./orders";


const { Title } = Typography;
  
// table code start
const columns = [
  {
      title: "ORDERED ON",
      dataIndex: "date",
      key: "date",
      width: "32%",
  },
  {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
      width: "32%",
  },
  {
    title: "QUANTITY",
    dataIndex: "quantity",
    key: "quantity",
    width: "32%",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    width: "7%",
  },
  {
    title: "ACTION",
    key: "status",
    dataIndex: "action",
    width: "7%",
  }
];


const App = ({show, setOpenEmployeeModal, order, refresh_top, permissionsprop}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderlines, setOrderLines] = useState(order? order.orderlines : []);
  const [openAddModal, setAddModal] = useState(false)
  const [data, setData] = useState([])
  const [employee, setEmployee] = useState(null);
  const [action, setAction] = useState('create');
  const [permissions, setPermissions] = useState([]);
  useEffect(()=>{
    setPermissions(permissionsprop);
  })
  useEffect(() => {
    setOrderLines(tableDateFormater(order? order.orderlines : []));
  }, [show]);
 
  const refresh = () => {
    const url = process.env.REACT_APP_API_URI+`/orders?pos_ref_no=${order?.pos_ref_no}`;
    axios.get(url, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(res =>{
      refresh_top()
      setOrderLines(tableDateFormater(res.data.res[0].orderlines));
    }).catch(error =>{
      console.log(error)
      requestError({error : error})
    })
  }

  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;

  const deleteEmployee = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/orders/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      requestError({error : error})
    })
  }

  
  const tableDateFormater = (data) =>{
    var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: element?.id,
            date : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element.date}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            product : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element.product?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            quantity : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{parseFloat((element.quantity || 0)/(element?.measurement?.quantity || 1)).toFixed(2)} {element?.measurement?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            status: (
              <> 
                  
                  <Tag color={
                      element?.status == 'delivered'?
                      "green":
                      element?.status == 'cancelled'?
                      "red":
                      element?.status == 'intransit'?
                      "blue":
                      ""
                  } key={`Active`}>
                      {element?.status.toUpperCase()}
                  </Tag>
                  
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "update_order")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setAddModal(true)}} type="" className="ant-btn-sm m-5"><EyeOutlined />Open</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "delete_order")?
                  <Button onClick={()=>{Modal.confirm({
                    title  : "Are you sure, you want to delete",
                    onCancel : ()=>console.log('cancelled'),
                    onOk : ()=>deleteEmployee(element.id)
                  })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      return arrayList;
  }

  return (
    <>
    
      <Modal 
      width={'60%'}
      title={`ORDER ${order?.pos_ref_no}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[]}
      >
        <div className="table-responsive">
            <Table
            columns={columns}
            dataSource={orderlines}
            pagination={true}
            className="ant-border-space"
            />
        </div>
      </Modal>
      <ModalAdd permissions={permissions} refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setAddModal} show = {openAddModal}/>
    </>
  );
};

export default App;