import { Button, Modal,Input, Popconfirm, Table, Avatar, Typography, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/global';
const { Title } = Typography;
const { Option } = Select;

const ProductCategoriesModal = ({show, setProductCategoriesModal, permissions, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignedProductCategories, setProductCategories] = useState([]);
  const [state, setState] = useState("");

  const columns = [
    {
      title: "Product Category",
      width: "32%",
      editable: true,
      render(data){
        return data.key != 'create'?
        <div>{data.name}</div>
        :
        <div className="author-info">
            <Input name='name' onChange={valuePicker}></Input>      
        </div>
      }
    },
    {
      title: "ACTION",
      render(data){
        return data.key == 'create'?
        <>
            {
                permissions.some(x=>x.permission == "create_product_category")?
                <Button onClick={()=>{
                save({name : state?.name, company_id : employee?.id});
                setLoading(true)
            }} type='primary'  className="ant-btn-sm m-5"> Create Product Category </Button> 
                :
                ""
            }
        </>
        :
        <div>{data.action}</div>
      },
      width: "7%",
    }
  ];
  useEffect(() => {
    setProductCategories([]);
    getProductCategories();

    if(action== `edit`){
      clear();
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setState({});
  }

  const deleteAllocation = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/productcategories/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      message.success(data.data.res);
      getProductCategories();
    }).catch(error =>{ 
      setLoading(false);
      requestError({error : error})
    })
  }

  const getProductCategories = () =>{
    axios.get(process.env.REACT_APP_API_URI+`/productcategories/company/${employee?.id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        console.log(data.data)
        var dataList =[];
        data.data.forEach(element => {
            dataList.push(
                {
                  key: element.id,
                  name: (
                    <>
                      <div className="author-info">
                        <p> {element.name}</p>
                      </div>
                    </>
                  ),
                  action: (
                    <>
                      {
                        permissions.some(x=>x.permission == "delete_product_category")?
                        <Button onClick={()=>{deleteAllocation(element.id)}} type="" className="ant-btn-sm m-5" danger>Delete Product Category</Button> 
                        :
                        ""
                      }
                    </>
                  ),
                }
              )
        });
        dataList.push({
            key : 'create',
          });
        setProductCategories(dataList);
    }).catch(error =>{
      requestError({error:error})
    })
  }

  const save = (data) =>{
    axios.post(process.env.REACT_APP_API_URI+`/productcategories/`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      getProductCategories();
      setLoading(false);
      clear();
      refresh();
    }).catch(error=>{
      setLoading(false);
      requestError({error:error})
    })
  }

  const [loading, setLoading] = useState(false)
  const valuePicker = (event) =>{
    setState({[event.target.name] : event.target.value})
  }


  

  return (
    <>
    
      <Modal 
      width={'50%'}
      title={`Product Categories For Company No.  ${employee?.id}` }
      visible={isModalVisible} 
      onOk={()=>setProductCategoriesModal(false)} 
      onCancel={()=>setProductCategoriesModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setProductCategoriesModal(false)}}>
            Close
        </Button>       
      ]}
      >
        <div className="table-responsive">
            <Table
                columns={columns}
                dataSource={assignedProductCategories}
                pagination={false}
                className="ant-border-space"
            />
        </div>
      </Modal>
    </>
  );
};

export default ProductCategoriesModal;
