import { Button, Modal, Input, Form, Select, message, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
 
  const [reason , setReason] = useState("")

    
  useEffect(() => {
    clear();
  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setReason("")
  }


  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const decline = (id) =>{
    axios.put(process.env.REACT_APP_API_URI+`/leaves/decline/${id}`,{}, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
      setOpenEmployeeModal(false);
    }).catch(error =>{
      requestError({error : error})
    }).finally(done=>{
        setLoading(false);
    })
  }

  return (
    <>
    
      <Modal 
      title={`LEAVE # ${action != `create`?`FOR #${employee?.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{decline(employee.id); setLoading(true)}}>
            Decline
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
            label = "Why do you want to decline this leave?"
            rules={[
                { required: true, message: "" },
            ]}>
              <Input value={reason} onChange={(event)=>setReason(event.target.value)} />
        </Form.Item>
      </Form>
      </Modal>
    </>
  );
};

export default App;