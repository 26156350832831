
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Tag,
    Modal
  } from "antd";
  
  import {useEffect, useState} from 'react';
  import EmployeeModal from "../components/modals/companies"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
import { confirmDelete, requestError } from "../controllers/global";
import ProductCategoriesModal from "../components/modals/ProductCategories";

    
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "EMAIL",
      key: "email",
      dataIndex: "email",
      width: "15%",
    },
    {
        title: "PHONE",
        key: "phone",
        dataIndex: "phone",
        width: "15%",
      },
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [companies, setCompanies] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [permissions, setPermissions] = useState(permissionsprop);
    const [openProductCategoriesModal, setProductCategoriesModal] = useState(false)

  
    useEffect(() => {
      refresh();
      setPermissions(permissions);
    }, [permissions]);
  
    const refresh = () => {
      axios.get(process.env.REACT_APP_API_URI+"/companies/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        var arrayList = [];
        setData(data.data.res)
        setCompanies(tableDateFormater(data.data.res));
      }).catch(error =>{
        requestError({error : error})
      })
    }

    const tableDateFormater = (data) =>{
      var arrayList = [];
        data.forEach(element => {
          arrayList.push(
            {
              key: "1",
              name: (
                <>
                    <div className="avatar-info">
                      <p>{element.name}</p>
                    </div>
                </>
              ),
              address: (
                <>
                  <div className="author-info">
                    <p> {element.address}</p>
                  </div>
                </>
              ),
              email: (
                <>
                  <div className="author-info">
                    <p>{element.email}</p>
                  </div>
                </>
              ),
              phone: (
                <>
                  <div className="author-info">
                    <p>{element.phone}</p>
                  </div>
                </>
              ),
              action: (
                <>
                  {
                    permissions.some(x=>x.permission == "create_product_category")?
                    <Button onClick={()=>{setAction('edit'); setEmployee(element); setProductCategoriesModal(true)}} type="" className="ant-btn-sm m-5"> Product Categories</Button>
                    :
                    ""
                  }
                  {
                    permissions.some(x=>x.permission == "update_company")?
                    <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                    :
                    ""
                  }
                  {
                    permissions.some(x=>x.permission == "delete_company")?
                    <Button onClick={()=>{
                      Modal.confirm({
                        title  : "Are you sure, you want to delete",
                        onCancel : ()=>console.log('cancelled'),
                        onOk : ()=>deleteCompany(element.id)
                      })
                    }} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                    :
                    ""
                  }
                </>
              ),
            }
          )
        });
        return arrayList;
    }

    const search = (value) =>{
      const filteredData = data.filter(x =>
          x.name.toUpperCase().includes(value.toUpperCase()) 
        );
        setCompanies(tableDateFormater(filteredData));
    }
  
  
    const deleteCompany = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/companies/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          refresh();
        }).catch(error =>{
          requestError({error : error})
        })
    }

    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="COMPANIES"
                extra={
                  <>
                    <Row>
                      <Col>
                        <Input onChange={(event) => search(event.target.value)} placeholder="Search Companies" />
                      </Col>
                      <Col>
                        <Button onClick={()=>{setAction(`create`);setOpenEmployeeModal(true); console.log("open")}} >ADD COMPANIES</Button>
                        <a href={process.env.REACT_APP_API_URI+`/companies/excel?token=${localStorage.getItem('token')}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                    </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={companies}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <EmployeeModal refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
        <ProductCategoriesModal show={openProductCategoriesModal} refresh ={refresh} setProductCategoriesModal={setProductCategoriesModal} permissions = {permissionsprop} employee={employee}/>
      </>
    );
  }
  
  export default EmployeesTable;
  