
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Tag,
    Modal,
    Image
  } from "antd";
  import moment from "moment"
  import {useEffect, useState} from 'react';
  import ModalAdd from "../components/modals/PromotionProducts"

  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined, EyeOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
  
  import PictureUpload from '../components/modals/pictureupload';
  import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
      title: "Promotion Image",
      dataIndex: "image",
      key: "image",
      width: "15%",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "7%",
    },
    {
      title: "Promotion",
      dataIndex: "name",
      key: "name"
    },
    
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    
    {
      title: "PERIOD",
      dataIndex: "period",
      key: "period",
    },
    
    {
      title: "OUTLET",
      dataIndex: "outlet",
      key: "outlet",
    }
    ,
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [products, setProducts] = useState([]);
    const [action, setAction] = useState('create');
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])


    if(permissionsprop != permissions){
      setPermissions(permissionsprop);
    }
  
    useEffect(() => {
      refresh();
    }, [permissions]);

    useEffect(() => {
      refresh();
    }, [reportPeriod]);
  
    const FacingImage = ({url}) =>{
      const [visible, setVisible] = useState(false);
      return  <>
      <Image
        preview={{
          visible: false,
        }}
        width={70}
        height={70}
        src={url}
        onClick={() => setVisible(true)}
      />
      <div
        style={{
          display: 'none',
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}
        >
          <Image src={url} />
          <Image src={url} />
          <Image src={url} />
        </Image.PreviewGroup>
      </div>
    </>
    }
  
    const refresh = () => {
      const url = process.env.REACT_APP_API_URI+`/outletpromotions?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`;
      axios.get(url, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data);
        setEmployees(tableDateFormater(data.data));
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
    
    const tableDateFormater = (data) =>{
      var arrayList = [];
        data.forEach(element => {
          arrayList.push(
            {
              key: element.id,
              image : (
                <>
                  <Avatar.Group>
                    <FacingImage url={element?.image} />
                  </Avatar.Group>{" "}
                </>
              ),
              date : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.date}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              name : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              description : (
                <>
                  {element?.description}
                </>
              ),
              period : (
                <>
                  {moment(element?.startDate).format("DD/MM/YYYY")} to {moment(element?.endDate).format("DD/MM/YYYY")}
                </>
              ),
              outlet: (
                <>
                  <div className="author-info">
                    <p> {element?.outlet?.name}</p>
                  </div>
                </>
              ),
              company: (
                <>
                  <div className="author-info">
                    <p> {element?.company?.name}</p>
                  </div>
                </>
              ),
              action: (
                <>
                  {
                    (element?.products || []).length > 0 && <Button onClick={()=>{setAction('edit'); setProducts(element?.products || []); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EyeOutlined /> Products </Button>
                  }
                  {
                    permissions.some(x=>x.permission == "delete_facing")?
                    <Button onClick={()=>{
                      Modal.confirm({
                        title  : "Are you sure, you want to delete",
                        onCancel : ()=>console.log('cancelled'),
                        onOk : ()=>deleteEmployee(element.uui)
                      })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                    :
                    ""
                  }
                </>
              )
            }
          )
        });
        return arrayList;
    }
  
    const search = (value) =>{
      const filteredData = data.filter(x =>
          x?.product?.name.toUpperCase().includes(value.toUpperCase()) ||
          x?.outlet?.name.toUpperCase().includes(value.toUpperCase()) ||
          x?.company?.name.toUpperCase().includes(value.toUpperCase()) 
        );
        setEmployees(tableDateFormater(filteredData));
    }
  
  
    const deleteEmployee = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/outletpromotions/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="OUTLET PROMOTIONS"
                extra={
                  <>
                    <Row>
                    <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event)=>search(event.target.value)} placeholder="Search..." />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/outletpromotions/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <ModalAdd products={products} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      </>
    );
  }
  
  export default EmployeesTable;
  