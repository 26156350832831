import { Button, Modal, Input, Form, Select, message, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh, permissions}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [cancellation_reason, setCancellationReason] = useState("")
  const [quantity, setQuantity] = useState(0)
  const [status, setStatus] = useState('')
  const [enteredStatus, setEnteredStatus] = useState('')
  const [company_id, setCompanyId] = useState('')
  const [statuses, setStatuses] = useState(['delivered', 'cancelled', 'intransit', 'ordered'])
    
  useEffect(() => {
    console.log(employee)
    if(action== `edit`){
      clear();
      setQuantity((employee?.quantity || 0)/ (employee?.measurement?.quantity || 1))
      setCompanyId(employee?.company_id)
      setCancellationReason(employee?.cancellation_reason)
      setStatus(employee?.status)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setCompanyId("")
    setStatus("")
    setCancellationReason("")
    setQuantity(0);
    setEnteredStatus("")
  }

  const edit = (event) =>{
    const data = {
      quantity : quantity*(employee?.measurement?.quantity || 1),
      company_id : company_id,
      cancellation_reason : cancellation_reason,
    } 
    axios.put(process.env.REACT_APP_API_URI+`/orders/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
      setLoading(false);
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;

  const transport = (id) =>{
    axios.get(process.env.REACT_APP_API_URI+`/orders/transport/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
      setOpenEmployeeModal(false);
    }).catch(error =>{
      requestError({error : error})
    })
  }

  const cancel = (id) =>{
    axios.get(process.env.REACT_APP_API_URI+`/orders/cancel/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      requestError({error : error})
    })
  }

  return (
    <>
    
      <Modal 
      title={`ORDER DETAILS ${action != `create`?`FOR #${employee?.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type="" danger  onClick={()=>{setOpenEmployeeModal(false)}}>
            Close
        </Button>,
        <Button onClick={()=>{
          Modal.confirm({
            title  : "Are you sure, you want to cancel the Order?",
            onCancel : ()=>console.log('cancelled'),
            onOk : ()=>{
              edit();
              cancel(employee?.id);
            }
          })
          
        }
        } disabled={status != "ordered"} style={{backgroundColor:"#C4A21C", color:"white"}} className="m-5" >Cancel Order</Button>,
        
        permissions.some(x=>x.permission == "transport_order")?
        <Button onClick={()=>{
          Modal.confirm({
            title  : "Are you sure, you want to change order status to deliver this order?",
            onCancel : ()=>console.log('cancelled'),
            onOk : ()=>{
              edit();
              transport(employee?.id);
            }
          })
          
        }
        } disabled={status != "ordered"} style={{backgroundColor:"#3CB804", color:"white"}} type="primary" className="m-5" >Transport</Button>
        :
        ""
        ,
          <Button disabled={status != "ordered"} key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save Order
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = {`Order Quantity in  ${employee?.measurement?.name}(s)`}
        rules={[
            { required: true, message: `Please input Quantity in ${employee?.measurement?.name}!` },
        ]}>
          <Input disabled={status != "ordered"} value={quantity} onChange={(event)=>setQuantity(event.target.value)} name = "quantity" placeholder="Order Quantity e.g 0" />
        </Form.Item>
        
        {(status == "ordered" )&& <Form.Item
          label = "Do you want to cancel this Order?"
          rules={[
              { required: true, message: "" },
          ]}>
            <Checkbox checked={enteredStatus == "cancelled" || status=="cancelled" } onChange={(event)=>{setEnteredStatus("cancelled");}} name = "quantity" placeholder="Order Quantity e.g 0"> {" "} yes {" "}</Checkbox>
            <Checkbox checked={enteredStatus == "ordered"} onChange={(event)=>{ setEnteredStatus("ordered"); }} name = "quantity" placeholder="Order Quantity e.g 0"> {" "} No {" "}</Checkbox>
          </Form.Item>
        }

        {
          enteredStatus == "cancelled" || status == "cancelled"?
            <Form.Item
            label = "Why do you want to cancel this Order?"
            rules={[
                { required: true, message: "" },
            ]}>
              <Input disabled={status != "ordered"} value={cancellation_reason} onChange={(event)=>setCancellationReason(event.target.value)} />
            </Form.Item>
            :
            ""
        }
      </Form>
      </Modal>
    </>
  );
};

export default App;