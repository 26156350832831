
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Tag,
    Space,
    DatePicker
  } from "antd";
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import {useEffect, useState} from 'react';
  import Modal from "../components/modals/orders"
  import axios from 'axios';
  import moment from 'moment'
  import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";

  
  const { Title } = Typography;
  const { RangePicker } = DatePicker;

  
  // table code start.
  const columns = [
    {
        title: "DATE",
        dataIndex: "date",
        key: "date",
        width: "7%",
    },
    {
        title: "USER",
        dataIndex: "user",
        key: "user",
        width: "20%",
    },
    {
        title: "ACTIVITY",
        dataIndex: "activity",
        key: "activity",
    },
    {
      title: "ACTIVITY TYPE",
      dataIndex: "type",
      key: "type",
      width: "15%",
    },
    {
      title: "OUTLET",
      dataIndex: "outlet",
      key: "outlet",
      width: "20%",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
      width: "20%",
    }
  ];

  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])

  
   
    useEffect(() => {
      axios.get(process.env.REACT_APP_API_URI+"/auth/my/permissions", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          setPermissions(data.data);
          refresh(data.data);
        }).catch(error =>{
          console.log(error)
        })
    }, []);

    // Refresh on period change
    useEffect(() => {
      refresh();
    }, [reportPeriod]);
   
    const refresh = (p) => {

      axios.get(process.env.REACT_APP_API_URI+`/activities?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data)
        setEmployees(tableDateFormater(data.data));
      }).catch(error =>{
        requestError({error : error})
      })
    }

    
  const tableDateFormater = (data) =>{
    var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: element.id,
            date : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.date}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            user : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element?.creator?.name}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            activity : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element.activity}</Title>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            type : (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <div>{
                    element.type == 'my'? 
                      <small>{element.type.toUpperCase()} ACTIVITY </small>
                      : 
                      <div>
                          <small>{element.type.toUpperCase()} ACTIVITY </small>
                          <br></br>
                          [ {element.competitor} ]
                      </div>

                    }</div>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            outlet: (
              <>
                <div className="author-info">
                  <p> {element?.outlet?.name}</p>
                </div>
              </>
            ),
            company: (
              <>
                <div className="author-info">
                  <p> {element?.company?.name}</p>
                </div>
              </>
            )
          }
        )
      });
      return arrayList;
  }

  const search = (value) =>{
    const filteredData = data.filter(x =>
        x?.creator?.name?.toUpperCase().includes(value.toUpperCase()) ||
        String(x.competitor).toUpperCase().includes(value.toUpperCase()) ||
        x.activity.toUpperCase().includes(value.toUpperCase()) ||
        x.type.toUpperCase().includes(value.toUpperCase()) ||
        x.date.toUpperCase().includes(value.toUpperCase()) ||
        x.outlet?.name.toUpperCase().includes(value.toUpperCase()) ||
        x?.company?.name.toUpperCase().includes(value.toUpperCase()) 
      );
      setEmployees(tableDateFormater(filteredData));
  }

    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="ACTIVITIES"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event)=>search(event.target.value)} placeholder="Search by Employee name..." />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/activities/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        {/* <Modal refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/> */}
        {/* <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/> */}
      </>
    );
  }
  
  export default EmployeesTable;
  