
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Tag
  } from "antd";
  import moment from "moment"
  import {useEffect, useState} from 'react';
  import Modal from "../components/modals/coolerfault_comment"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined, CommentOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";
  
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
        title: "DATE",
        dataIndex: "date",
        key: "date",
        width: "15%",
    },
    {
      title: "COOLER",
      dataIndex: "cooler",
      key: "cooler",
      width: "30%",
    },
    {
      title: "OUTLET",
      dataIndex: "outlet",
      key: "outlet",
      width: "32%",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: "25%",
    },
    {
      title: "FAULT",
      dataIndex: "fault",
      key: "fault",
    },
    {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        width: "7%",
    },
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])


    if(permissionsprop != permissions){
      setPermissions(permissionsprop);
    }

    useEffect(()=>{
      refresh();
    }, [permissions]);

  
    useEffect(()=>{
      refresh()
    }, [reportPeriod]);

    const refresh = () => {
      axios.get(process.env.REACT_APP_API_URI+`/coolerfaults?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data);
        setEmployees(tableDateFormater(data.data));
      }).catch(error =>{
        requestError({error : error})
      })
    }

    const update = (id) => {
      axios.put(process.env.REACT_APP_API_URI+`/coolerfaults/${id}`, {status : "resolved"}, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        message.success("Cooler fault successfully updated");
        refresh()
      }).catch(error =>{
        requestError({error : error})
      })
    }

    
const search = (value) =>{
  const filteredData = data.filter(x =>
      x.status.toUpperCase().includes(value.toUpperCase()) ||
      x.cooler?.serial_number.toUpperCase().includes(value.toUpperCase()) ||
      x.cooler?.name.toUpperCase().includes(value.toUpperCase()) ||
      x.cooler?.company?.name.toUpperCase().includes(value.toUpperCase()) ||
      x.outlet?.name.toUpperCase().includes(value.toUpperCase()) 
    );
    setEmployees(tableDateFormater(filteredData));
}

  
const tableDateFormater = (data) =>{
  var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: element.id,
            date: (
              <>
                  <div className="avatar-info">
                    <p>{element.date}</p>
                  </div>
              </>
            ),
            cooler: (
              <>
                  <div className="avatar-info">
                    <p>{element.cooler?.name} ({element.cooler?.serial_number})</p>
                  </div>
              </>
            ),
            outlet: (
              <>
                <div className="author-info">
                  <p> {element?.outlet?.name}</p>
                </div>
              </>
            ),
            company: (
              <>
                  <div className="avatar-info">
                    <p>{element.company?.name}</p>
                  </div>
              </>
            ),
            fault: (
              <>
                  <div className="avatar-info">
                    <p>{element.fault}</p>
                  </div>
              </>
            ),
            status: (
              <>
                <div className="author-info">
                  <p> {element.status}</p>
                  <p> {element?.turnAroundTime}</p>
                </div>
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "create_coolerfault_comment")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><CommentOutlined /> Comment</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "update_coolerfault")?
                  <Button onClick={()=>{update(element?.id)}} style={element?.status == "resolved"?{background:"#2cd52c", color:"white"} : {}} type="" className="ant-btn-sm m-5"> {element?.status == "resolved"?'Completed' : "Complete"} </Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "delete_coolerfault")?
                  <Button onClick={()=>{
                    Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteCoolerFault(element.id)
                    })
                    
                  }} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      return arrayList;
}
  
    const deleteCoolerFault = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/coolerfaults/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error : error})
      })
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="COOLER FAULTS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event) =>search(event.target.value)} placeholder="Search by cooler name or serial number" />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/coolerfaults/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      </>
    );
  }
  
  export default EmployeesTable;
  