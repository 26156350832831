import { useState, useEffect } from "react";
import axios from 'axios';

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Tag,
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
} from "@ant-design/icons";

import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import ava1 from "../assets/images/logo-shopify.svg";
// import team1 from "../assets/images/team-1.jpg";
import card from "../assets/images/info-card-1.jpg";
import moment from "moment";
import { requestError } from "../controllers/global";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const profile = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
      fill="#fff"
    ></path>
    <path
      d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
      fill="#fff"
    ></path>
    <path
      d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
      fill="#fff"
    ></path>
    <path
      d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
      fill="#fff"
    ></path>
  </svg>,
];
const heart = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
      fill="#fff"
    ></path>
  </svg>,
];
const cart = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
      fill="#fff"
    ></path>
  </svg>,
];


var count_display = [
  {
    today: `Attendace Today`,
    title: 0,
    icon: cart, //dollor,
    bnb: "bnb2",
    link : '/loginreport'
  },
  {
    today: `Not Logged In Pass 5 Days`,
    title: 0,
    icon: profile, 
    bnb: "bnb2",
    link : '/loginreport'
  },
  {
    today: "Leave (Pending)",
    title: 0,
    // icon: cart, //profile,
    bnb: "bnb2",
    link : '/leaves'
  },
  {
    today: "People On Leave",
    title: 0,
    icon: profile, //profile,
    bnb: "bnb2",
    link : '/leaves'
  },
  {
    today: "Orders waiting for delivery",
    title: 0,
    // icon: cart,
    bnb: "redtext",
    link : '/orders'
  },
  {
    today: "Expiries (In less 5 days)",
    title: 0,
    // icon: cart,
    bnb: "redtext",
    link : '/expiries'
  },
  {
    today: "Cooler Faults (Active)",
    title: 0,
    // icon: profile,
    bnb: "bnb2",
    link : '/coolerfaults'
  },
  {
    today: "Today Activities",
    title: 0,
    // icon: profile,
    bnb: "bnb2",
    link : '/activities'
  },
  {
    today: "Total Users",
    title: 0,
    icon: profile,
    bnb: "bnb2",
    link : '/employees'
  },
  {
    today: "Total Coolers",
    title: 0,
    // icon: profile,
    bnb: "bnb2",
    link : '/coolers'
  },
  {
    today: "Total Products",
    title: 0,
    icon: cart,
    bnb: "bnb2",
    link : '/products'
  },
  {
    today: "Total Outlets",
    title: 0,
    // icon: profile,
    bnb: "bnb2",
    link : '/outlets'
  },
  {
    today: "Orders In Transit",
    title: 0,
    // icon: profile,
    bnb: "bnb2",
    link : '/outlets'
  },
  {
    today: "Company Promotions",
    title: 0,
    // icon: profile,
    bnb: "bnb2",
    link : '/promotions'
  },
  {
    today: "Outlet Promotions",
    title: 0,
    bnb: "bnb2",
    link : '/outletpromotions'
  }
];

function Home({token, permissionsprop}) {


  const [count, setCounts] = useState([]);
  const [cooler_faults, setCoolerFaults] = useState([]);
  const [monthlyAttendance, setMonthlyAttendance] = useState([]);
  const [xaxis, setXaxis] = useState([]);


  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URI+"/dashboard/graph/feeds/attendance", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(res =>{
      setMonthlyAttendance(res.data.attendance);
      setXaxis(res.data.monthDays)
    })

    axios.get(process.env.REACT_APP_API_URI+`/dashboard/totals`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          var d = count_display;
          d[0].title = data.data.attendance
          d[2].title = data.data.pendingLeaves
          d[4].title = data.data.waitingOrders
          d[6].title = data.data.activeCoolerFaults
          d[5].title = data.data.expiries
          d[7].title = data.data.activities

          d[9].title  = data.data.total_coolers
          d[10].title  = data.data.total_products
          d[11].title  = data.data.total_outlets

          d[3].title = data.data.users_on_leave_today
          d[1].title = data.data.users_not_logged_in_for_last_5_days
          d[8].title = data.data.total_users
          d[12].title = data.data.orders_in_delivery
          d[13].title = data.data.total_company_promotions
          d[14].title = data.data.total_outlet_company_promotions
          

          if(permissionsprop?.some(x=>x.permission == "get_subordinates_dashboard_totals")){
            d = d.filter(x => x.today  !== "Total Products")
            d = d.filter(x => x.today  !== "Company Promotions")
          }

          setCounts(d);
      }).catch(error =>{
        requestError({error : error})
      })

  }, [token, permissionsprop]);

  const { Title, Text } = Typography;

  const [reverse, setReverse] = useState(false);


  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card  bordered={false} className="criclebox ">
                <NavLink to={c?.link}>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span>{c.today}</span>
                        <Title level={3}>
                          {c.title} <small className={c.bnb}></small>
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box">{c.icon}</div>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Card>
            </Col>
          ))}
        </Row>

        
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart 
              days={xaxis} 
              data= {monthlyAttendance} />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Row gutter>
                <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={14}
                  className="mobile-24"
                >
                  <div className="h-full col-content p-20">
                    <div className="ant-muse">
                      <Text>Take A read ...</Text>
                      <Title level={5}>Winds Data Policy</Title>
                      <Paragraph className="lastweek mb-36">
                        From colors, cards, typography to complex elements, you
                        will find the full documentation.
                      </Paragraph>
                    </div>
                    <div className="card-footer">
                      <a className="icon-move-right" href="#pablo">
                        Read More
                        {<RightOutlined />}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={10}
                  className="col-img"
                >
                  <div className="ant-cret text-right">
                    <img src={card} alt="" className="border10" />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox card-info-2 h-full">
              <div className="gradent h-full col-content">
                <div className="card-content">
                  <Title level={5}>Explore Winds Features</Title>
                  <p>
                    Wealth creation is an evolutionarily recent positive-sum
                    game. It is all about who take the opportunity first.
                  </p>
                </div>
                <div className="card-footer">
                  <a className="icon-move-right" href="#pablo">
                    Read More
                    <RightOutlined />
                  </a>
                </div>
              </div>
            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}

export default Home;
