import { Button, Modal,Input, Popconfirm, Table, Avatar, Typography, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/global';
const { Title } = Typography;
const { Option } = Select;

const App = ({show, setopenAsingOutletsModal, permissions, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const [outlet_id, setOutletId] = useState("")
  const [company_id, setCompanyId] = useState('')

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

  const columns = [
    {
      title: "OUTLET",
      width: "32%",
      editable: true,
      render(data){
        return data.key != 'create'?
        <div>{data.outlet}</div>
        :
        <div className="author-info">
                    <Select
                        showSearch
                        onChange={(value)=>{
                            setOutletId(value);
                        }}
                        style={{
                        width: 200,
                        }}
                        value={outlet_id}
                        optionFilterProp="children"
                    
                    >
                        {
                        outlets.map(item =>
                            <Option key={item.id} value={item.id}>{item.name}</Option>
                            )
                        }
                    </Select>
                </div>
      }
    },
    {
      title: "COMPANY",
      render(data){
        return data.key != 'create'?
        <div>{data.company}</div>
        :
        <div className="author-info">
                    <Select
                        showSearch
                        onChange={(value)=>{
                            setCompanyId(value)
                        }}
                        style={{
                        width: 200,
                        }}
                        value={company_id}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                        companies.map(item =>
                            <Option value={item.id}>{item.name}</Option>
                            )
                        }
                    </Select>
                </div>
      }
    },
    {
      title: "ACTION",
      render(data){
        return data.key == 'create'?
        <>
            {
                permissions.some(x=>x.permission == "create_user_outlet")?
                <Button onClick={()=>{
                console.log({user_id : employee.id,company_id : company_id, outlet_id : outlet_id})
                save({user_id : employee.id,company_id : company_id, outlet_id : outlet_id});
                setLoading(true)
            }} type="primary" className="ant-btn-sm m-5">Allocate</Button> 
                :
                ""
            }
        </>
        :
        <div>{data.action}</div>
      },
      width: "7%",
    }
  ];
  useEffect(() => {
    setAssignedLocations([]);
    axios.get(process.env.REACT_APP_API_URI+"/companies/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setCompanies(data.data.res);
    }).catch(error =>{
      // requestError({error : error})
    })
    axios.get(process.env.REACT_APP_API_URI+"/outlets/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setOutlets(data.data.res);
    }).catch(error =>{
      // requestError({error : error})
    })

    getAssignedLocations();

    if(action== `edit`){
      clear();
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setCompanyId("")
    setOutletId("")
  }

  const deleteAllocation = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/users/outlet/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      message.success(data.data.res);
      getAssignedLocations();
    }).catch(error =>{
      setLoading(false);
      requestError({error : error})
    })
  }

  const getAssignedLocations = () =>{
    axios.get(process.env.REACT_APP_API_URI+`/users/outlets/${employee?.id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        console.log(data.data)
        var dataList =[];
        data.data.forEach(element => {
            dataList.push(
                {
                  key: element.id,
                  outlet: (
                    <>
                      <div className="author-info">
                        <p> {element.outlet?.name}</p>
                      </div>
                    </>
                  ),
                  company: (
                    <>
                      <div className="author-info">
                        <p> {element.company?.name}</p>
                      </div>
                    </>
                  ),
                  action: (
                    <>
                      {
                        permissions.some(x=>x.permission == "delete_user_outlet")?
                        <Button onClick={()=>{deleteAllocation(element.id)}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                        :
                        ""
                      }
                    </>
                  ),
                }
              )
        });
        dataList.push({
            key : 'create',
          });
        setAssignedLocations(dataList);
    }).catch(error =>{
      requestError({error:error})
    })
  }

  const save = (data) =>{
    axios.post(process.env.REACT_APP_API_URI+`/users/outlet/`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      getAssignedLocations();
      setLoading(false);
      clear();
      refresh();
    }).catch(error=>{
      setLoading(false);
      requestError({error:error})
    })
  }

  const [loading, setLoading] = useState(false)
  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }


  

  return (
    <>
    
      <Modal 
      width={'50%'}
      title={`USER DETAILS ${action != `create`?`FOR #${employee?.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setopenAsingOutletsModal(false)} 
      onCancel={()=>setopenAsingOutletsModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setopenAsingOutletsModal(false)}}>
            Close
        </Button>       
      ]}
      >
        <div className="table-responsive">
            <Table
                columns={columns}
                dataSource={assignedLocations}
                pagination={false}
                className="ant-border-space"
            />
        </div>
      </Modal>
    </>
  );
};

export default App;
