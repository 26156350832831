
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Modal,
    Tag
  } from "antd";
  
  import moment from "moment"
  import {useEffect, useState} from 'react';
  import ModalAdd from "../components/modals/orders"
  import axios from 'axios';
  import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
  import face2 from "../assets/images/face-2.jpg";
  
  import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";
import DatePickerComponent from "../components/date/period";
  
  const { Title } = Typography;
  
  // table code start
  const columns = [
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "7%",
    },
    {
        title: "PRODUCT",
        dataIndex: "product",
        key: "product",
        width: "15%",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
      width: "7%",
    },
    {
      title: "COMPETITOR",
      dataIndex: "competitor",
      key: "competitor",
      width: "15%",
    },
    {
      title: "COMPETITOR PRODUCT",
      dataIndex: "competitor_product",
      key: "competitor_price",
      width: "20%",
    },
    {
      title: "COMPETITOR PRICE",
      dataIndex: "competitor_price",
      key: "competitor_price",
      width: "7%",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
      width: "7%",
    },
    {
      title: "OUTLET",
      dataIndex: "outlet",
      key: "outlet",
      width: "7%",
    }
  ];
  function EmployeesTable({permissionsprop}) {
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [employees, setEmployees] = useState([])
    const [data, setData] = useState([])
    const [employee, setEmployee] = useState(null);
    const [action, setAction] = useState('create');
    const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])

  
    useEffect(()=>{
      setPermissions(permissionsprop);
    })
  
    useEffect(() => {
      refresh();
    }, [permissions]);
  
  
    useEffect(() => {
      refresh();
    }, [reportPeriod]);
  
    const refresh = () => {
      axios.get(process.env.REACT_APP_API_URI+`/surveys?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data);
        setEmployees(tableDateFormater(data.data));
      }).catch(error =>{
        requestError({error : error})
      })
    }

    const tableDateFormater = (data) =>{
      var arrayList = [];
        data.forEach(element => {
          arrayList.push(
            {
              key: "1",
              date : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.date}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              product : (
                <>
                  <Avatar.Group>
                    <div className="avatar-info">
                      <Title level={5}>{element.product?.name}</Title>
                    </div>
                  </Avatar.Group>{" "}
                </>
              ),
              price : (
                <>
                    <div className="avatar-info">
                    <Tag style={{width : "100%"}} color={
                        element.competitor_price > element.price ?
                        "green"
                        :
                        "red"
                    } key={`Active`}>
                        {element.price}
                    </Tag>
                    </div>
                </>
              ),
              
              competitor : (
                <>
                    <div className="avatar-info">
                      <Title level={5}>{element.competitor}</Title>
                    </div>
                </>
              ),
              
              competitor_product : (
                <>
                    <div className="avatar-info">
                      <Title level={5}>{element.competitor_product}</Title>
                    </div>
                </>
              ),
              
              competitor_price : (
                <>
                    <div className="avatar-info">
                      <Title level={5}>{element.competitor_price}</Title>
                    </div>
                </>
              ),
              outlet: (
                <>
                  <div className="author-info">
                    <p> {element?.outlet?.name}</p>
                  </div>
                </>
              ),
              company: (
                <>
                  <div className="author-info">
                    <p> {element?.company?.name}</p>
                  </div>
                </>
              )
            }
          )
        });
        return arrayList;
    }
  
    const search = (value) =>{
      const filteredData = data.filter(x =>
          x?.date.toUpperCase().includes(value.toUpperCase()) ||
          x?.competitor.toUpperCase().includes(value.toUpperCase()) ||
          x?.competitor_product.toUpperCase().includes(value.toUpperCase()) ||
          x?.product?.name.toUpperCase().includes(value.toUpperCase()) ||
          x?.outlet?.name.toUpperCase().includes(value.toUpperCase()) ||
          x?.company?.name.toUpperCase().includes(value.toUpperCase()) 
        );
      setEmployees(tableDateFormater(filteredData));
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="SURVEYS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                      </Col>
                      <Col>
                        <Input onChange={(event)=>search(event.target.value)} placeholder="Seach Order" />
                      </Col>
                      <Col>
                        <a href={process.env.REACT_APP_API_URI+`/surveys/excel?token=${localStorage.getItem('token')}&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        {/* <ModalAdd refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
        <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/> */}
      </>
    );
  }
  
  export default EmployeesTable;
  