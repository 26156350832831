import { Button, Modal, Input, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

    
  useEffect(() => {

    if(action== `edit`){
      clear();
      setName(employee?.name)
      setAddress(employee?.address)
      setEmail(employee?.email)
      setPhone(employee?.phone)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setName('')
    setAddress('')
    setEmail('')
    setPhone('')
  }

  const submit = (event) =>{
      const data = {
        name : name,
        address : address,
        phone : phone,
        email : email
      }

    axios.post(process.env.REACT_APP_API_URI+"/companies/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setLoading(false);
      setOpenPictureUploadModal(false);
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      if (error.response) {
        setLoading(false)
        // Server Error Massage
        message.error(error.response.data?.res)
        console.log(error.response.data?.res);
      } else if (error.request) {
        // Request was made but no response
        message.error(error.request)
        console.log(error.request);
      } else {
        // Network Error
        message.error(error.message)
        console.log('Error', error.message);
      }
    })
  }
  const edit = (event) =>{
    const data = {
        name : name,
        address : address,
        phone : phone,
        email : email
    } 
    axios.put(process.env.REACT_APP_API_URI+`/companies/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      setLoading(false);
      refresh();
    }).catch(error=>{
      if (error.response) {
        setLoading(false)
        // Server Error Massage
        message.error(error.response.data?.res)
        console.log(error.response.data?.res);
      } else if (error.request) {
        // Request was made but no response
        message.error(error.request)
        console.log(error.request);
      } else {
        // Network Error
        message.error( error.message)
        console.log('Error', error.message);
      }
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }
  return (
    <>
    
      <Modal 
      title={`COMPANY DETAILS ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
            Submit
          </Button>
          :
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "name"
        rules={[
            { required: true, message: "Please input your name!" },
        ]}>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Name e.g Test Pvt Ltd" />
        </Form.Item>

        <Form.Item
          label = "address"
          rules={[
              { required: true, message: "Please input your address!" },
          ]}
          >
          <Input value={address} onChange={(event)=>setAddress(event.target.value)} name = "address" placeholder="Address e.g 12 Samora, Harare" />
        </Form.Item>

        <Form.Item
          label = "phone"
          rules={[
              { required: true, message: "Please input your phone!" },
          ]}
          >
          <Input value={phone} onChange={(event)=>setPhone(event.target.value)} name = "phone" placeholder="phone e.g 077 000 0000" />
        </Form.Item>

        <Form.Item
          label = "email"
          rules={[
              { required: true, message: "Please input your email!" },
          ]}
          >
          <Input value={email} onChange={(event)=>setEmail(event.target.value)} name = "email" placeholder="email e.g test@test.com" />
        </Form.Item>

      </Form>
      </Modal>
    </>
  );
};

export default App;