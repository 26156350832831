
import { useState, useEffect } from "react";
import axios from "axios"

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import PictureUpload from '../components/modals/pictureupload';
// import IncidentModal from "../components/modals/incident"
import { requestError } from "../controllers/global";
import Title from "antd/lib/skeleton/Title";




function Profile({permissionsprop}) {
  return (
    <>
      <div style={{marginTop : "10%",marginLeft : "30%"}}>
        <div style={{color:"green"}}>Hi {localStorage.getItem("name")}, You are now logged into the platform</div>
      </div>
    </>
  );
}

export default Profile;
