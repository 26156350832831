import { Button, Modal,Input, Popconfirm, Table, Avatar, Typography, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from "moment"
import { requestError } from '../../controllers/global';
const { Title } = Typography;
const { Option } = Select;

const App = ({show, setopenAsingOutletsModal, permissions, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [assignedOffDays, setAssignedOffDays] = useState([]);
  const [type, setType ] = useState("")
  const [date, setDate] = useState('')

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

  const columns = [
    {
      title: "OFF DAY",
      width: "32%",
      editable: true,
      render(data){
        return data.key != 'create'?
        <div>{data.outlet}</div>
        :
        <div className="author-info">
            <Input value={date} onChange={(e) => setDate(e.target.value)} type = "date" />       
        </div>
      }
    },
    {
      title: "TYPE",
      render(data){
        return data.key != 'create'?
        <div>{data.company}</div>
        :
        <div className="author-info">
            <Select
                showSearch
                onChange={(value)=>{
                    setType(value);
                }}
                style={{
                width: 200,
                }}
                value={type}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.includes(input)}
                filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
            >
                {
                [ 
                    { value : "half_day", label : "Half Day"}, {label : "Full Day",  value : "full_day"}].map(item =>
                        <Option key={item.id} value={item.value}>{item.label}</Option>
                    )
                }
            </Select>
        </div>
      }
    },
    {
      title: "ACTION",
      render(data){
        return data.key == 'create'?
        <>
            {
                permissions.some(x=>x.permission == "create_user_outlet")?
                <Button onClick={()=>{
                save({employee_id : employee.id, date : date , type : type });
                setLoading(true)
            }} type="primary" className="ant-btn-sm m-5">Save Off day</Button> 
                :
                ""
            }
        </>
        :
        <div>{data.action}</div>
      },
      width: "7%",
    }
  ];
  useEffect(() => {

    setAssignedOffDays([]);

    getAssignedOffDays();

    if(action== `edit`){
      clear();
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setDate("");
    setType("");
  }

  const deleteAllocation = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/employee/offdays/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      message.success(data.data.res);
      getAssignedOffDays();
    }).catch(error =>{
      setLoading(false);
      requestError({error : error})
    })
  }

  const getAssignedOffDays = () =>{
    axios.get(process.env.REACT_APP_API_URI+`/employee/offdays/employee/${employee?.id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        var dataList =[];
        data.data.forEach(element => {
            dataList.push(
                {
                  key: element.id,
                  outlet: (
                    <>
                      <div className="author-info">
                        <p> {moment(element.date).format("ddd DD[,] MMM YYYY")}</p>
                      </div>
                    </>
                  ),
                  company: (
                    <>
                      <div className="author-info">
                        <p> {element.type}</p>
                      </div>
                    </>
                  ),
                  action: (
                    <>
                      {
                        permissions.some(x=>x.permission == "delete_user_outlet")?
                        <Button onClick={()=>{deleteAllocation(element.id)}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                        :
                        ""
                      }
                    </>
                  ),
                }
              )
        });
        dataList.push({
            key : 'create',
          });
        setAssignedOffDays(dataList);
    }).catch(error =>{
      requestError({error:error})
    })
  }

  const save = (data) =>{
    axios.post(process.env.REACT_APP_API_URI+`/employee/offdays/`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      getAssignedOffDays();
      setLoading(false);
      clear();
      refresh();
    }).catch(error=>{
      setLoading(false);
      requestError({error:error})
    })
  }

  const [loading, setLoading] = useState(false)

  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }


  

  return (
    <>
    
      <Modal 
      width={'50%'}
      title={`USER DETAILS ${action != `create`?`FOR #${employee?.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setopenAsingOutletsModal(false)} 
      onCancel={()=>setopenAsingOutletsModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setopenAsingOutletsModal(false)}}>
            Close
        </Button>       
      ]}
      >
        <div className="table-responsive">
            <Table
                columns={columns}
                dataSource={assignedOffDays}
                pagination={false}
                className="ant-border-space"
            />
        </div>
      </Modal>
    </>
  );
};

export default App;
