import { Button, Modal, Input, Form, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState("")
  const [longitude, setLongitudes] = useState("")
  const [latitude, setlatitude] = useState("")

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

    
  useEffect(() => {

    if(action== `edit`){
      clear();
      setName(employee?.name)
      setLongitudes(employee?.longitude)
      setlatitude(employee?.latitude)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }


  const clear = () =>{
    setName('')
    setLongitudes('')
    setlatitude('')
  }

  const submit = (event) =>{
      const data = {
        name : name,
        longitude : longitude,
        latitude : latitude
      }

    axios.post(process.env.REACT_APP_API_URI+"/outlets/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setLoading(false);
      setOpenPictureUploadModal(false);
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    })
  }
  const edit = (event) =>{
    const data = {
        name : name,
        longitude : longitude,
        latitude : latitude
    } 
    axios.put(process.env.REACT_APP_API_URI+`/outlets/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      setLoading(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }
  return (
    <>
    
      <Modal 
      title={`OUTLET DETAILS ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
            Submit
          </Button>
          :
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "name"
        rules={[
            { required: true, message: "Please input your name!" },
        ]}>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Name e.g T N T" />
        </Form.Item>

        <Form.Item
          label = "longitudes"
          rules={[
              { required: true, message: "Please input your longitude!" },
          ]}
          >
          <Input type='number' value={longitude} onChange={(event)=>setLongitudes(event.target.value)} name = "longitude" placeholder="GPS longitude..." />
        </Form.Item>

        <Form.Item
          label = "latitude"
          rules={[
              { required: true, message: "Please input your latitude!" },
          ]}
          >
          <Input type='number' value={latitude} onChange={(event)=>setlatitude(event.target.value)} name = "latitude" placeholder="GPS latitude..." />
        </Form.Item>

      </Form>
      </Modal>
    </>
  );
};

export default App;