
  import { App, Button, Space, message, notification, Modal } from 'antd'


const confirmDelete = ({message, func }) => {
  Modal.confirm({
    title  : "Are you sure, you want to delete",
    onCancel : ()=>console.log('cancelled'),
    onOk : ()=>{}
  })
}
const requestError = ({error}) => {
  if (error.response?.data) {
    message.error(error.response?.data?.res)
  } else if (error.request) {
    // Request was made but no response
    console.log(error.request)
    message.open({
      type : 'error',
      content : 'Server Application down'
    })
  } else {
    console.log(error.message)
    // Network Error
    message.open({
      type : 'error',
      content : 'Check your network connections'
    })
  }
}
export {requestError, confirmDelete};

