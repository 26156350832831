
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Modal,
  Tag
} from "antd";

import {useEffect, useState} from 'react';
import EmployeeModal from "../components/modals/employee"
import AsignOutlets from "../components/modals/AsignOutlets"
import EmpoyeeOffDays from "../components/modals/Employee.off.days"
import axios from 'axios';
import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
import face2 from "../assets/images/face-2.jpg";

import PictureUpload from '../components/modals/pictureupload';
import { requestError } from "../controllers/global";

const { Title } = Typography;

// table code start
const columns = [
  {
    title: "EMPLOYEE",
    dataIndex: "name",
    key: "name",
    width: '40%',
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    key: "function",
  },
  {
    title: "USERNAME",
    dataIndex: "username",
    key: "username",
    width: "15%",
    onFilter: (value, record) => record?.name.startsWith(value),
    filterSearch: true,
  },
  {
    title: "LEAVE DAYS",
    dataIndex: "available_leave_days",
    key: "available_leave_days",
    width: "15%",
  },
  {
    title: "HOLIDAY LEAVE DAYS",
    dataIndex: "available_holiday_leave_days",
    key: "available_holiday_leave_days",
    width: "7%",
  },
  {
    title: "ACCESS LEVEL",
    dataIndex: "accesslevel",
    key: "function",
    width: "15%",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
    width: "7%",
  },

  {
    title: "ACTION",
    key: "status",
    dataIndex: "action",
    width: "7%",
  }
];
function EmployeesTable({permissionsprop}) {
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
  const [data, setData] = useState([])
  const [employees, setEmployees] = useState([])
  const [employee, setEmployee] = useState(null);
  const [action, setAction] = useState('create');
  const [openAsingOutletsModal, setopenAsingOutletsModal] = useState(false)
  const [openEmployeeOffDaysModal, setOpenEmployeeOffDaysModal] = useState(false)
  const [permissions, setPermissions] = useState([]);

  useEffect(()=>{
    setPermissions(permissionsprop);
  }, [permissionsprop])

  useEffect(() => {
    refresh();
  }, [permissions]);

  const refresh = () => {
    axios.get(process.env.REACT_APP_API_URI+"/users/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setEmployees(tableDateFormater(data.data.res));
      setData(data.data.res);
    }).catch(error =>{
      // requestError({error : error})
    })
  }

  const deleteEmployee = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/users/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      requestError({error : error})
    })
  }

const search = (value) =>{
  const filteredData = data.filter(x =>
      (x.name || "").toUpperCase().includes(value.toUpperCase()) ||
      (x?.email || "").toUpperCase().includes(value.toUpperCase()) ||
      (x.username || "").toUpperCase().includes(value.toUpperCase()) ||
      (x.employee_number || "").toUpperCase().includes(value.toUpperCase()) ||
      (x.accesslevel?.name || "").toUpperCase().includes(value.toUpperCase())
    );
    setEmployees(tableDateFormater(filteredData));
}

  
const tableDateFormater = (data) =>{
  var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {
            key: "1",
            name: (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element.name} {"[ No. :"} { element?.employee_number } {"]"} </Title>
                    <p><small><MailTwoTone /> {element.email}</small> <small><PhoneTwoTone /> {element.phone}</small> </p>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            address: (
              <>
                <div className="author-info">
                  <p> {element.address}</p>
                </div>
              </>
            ),
            username: (
              <>
                <div className="author-info">
                  <p>{element.username}</p>
                </div>
              </>
            ),
            available_leave_days : (
              <>
                <div className="author-info">
                  <p>{element.available_leave_days}</p>
                </div>
              </>
            ),
            available_holiday_leave_days : (
              <>
                <div className="author-info">
                  <p>{element.available_holiday_leave_days}</p>
                </div>
              </>
            ),
            accesslevel: (
              <>
                <div className="author-info">
                  <p>{element.accesslevel?.name}</p>
                </div>
              </>
            ),
            status: (
              <>
                {
                  true?
                  <Tag color={"green"} key={`Active`}>
                    {`Active`.toUpperCase()}
                  </Tag>
                  :
                  <Tag color={"red"} key={`Active`}>
                    {`Active`.toUpperCase()}
                  </Tag>
                }
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "update_user")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "create_user_outlet")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setopenAsingOutletsModal(true)}} type="" className="ant-btn-sm m-5"> Outlets</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "create_off_day")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeOffDaysModal(true)}} type="" className="ant-btn-sm m-5"> Off Days</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "delete_user")?
                  <Button onClick={()=>{Modal.confirm({
                      title  : "Are you sure, you want to delete",
                      onCancel : ()=>console.log('cancelled'),
                      onOk : ()=>deleteEmployee(element.id)
                    })}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      return arrayList;
}

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="USERS"
              extra={
                <>
                  <Row>
                    <Col>
                      <Input onChange={(event)=>search(event.target.value)} placeholder="Search By, name, username, access level" />
                    </Col>
                    <Col>
                      <Button onClick={()=>{setAction(`create`);setOpenEmployeeModal(true); console.log("open")}} >ADD EMPLOYEE</Button>
                        <a href={process.env.REACT_APP_API_URI+`/users/excel?token=${localStorage.getItem('token')}`}><Button style={{borderColor : "green", color : "green"}} >EXPORT</Button></a>
                    </Col>
                    </Row>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={employees}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <EmployeeModal refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      <AsignOutlets show={openAsingOutletsModal} refresh ={refresh} setopenAsingOutletsModal={setopenAsingOutletsModal} permissions = {permissionsprop} employee={employee}/>
      <EmpoyeeOffDays show={openEmployeeOffDaysModal} refresh ={refresh} setopenAsingOutletsModal={setOpenEmployeeOffDaysModal} permissions = {permissionsprop} employee={employee}/>
    </>
  );
}

export default EmployeesTable;
