import { Button, Modal, Input, Form, Select, message, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PictureUpload from './pictureupload';
import moment from 'moment';
import { requestError } from '../../controllers/global';

const App = ({show, setOpenEmployeeModal, employee, action, refresh}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [product_id, setProductId] = useState(null);
  const [company_id, setCompanyId] = useState(null);
  const [companies, setCompanies] = useState([]);

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)

    
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URI+"/companies/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        setCompanies(data.data.res);
      }).catch(error =>{
        requestError({error : error})
      })
    if(action== `edit`){
      clear();
      setName(employee?.name)
      setDescription(employee?.description)
      setStart(moment(employee?.start))
      setEnd(moment(employee?.end))
      setCompanyId(employee?.company_id)
      setProductId(employee?.product_id)
    }

    if(action == `create`){
      clear();
    }

  }, [show]);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const getCompanyProducts = (id) =>{
    axios.get(process.env.REACT_APP_API_URI+`/products?company_id=${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setProducts(data.data);
    }).catch(error =>{
      requestError({error : error})
    })
  }
  const clear = () =>{
    setName("")
    setDescription("")
    setStart("")
    setEnd("")
    setCompanyId(null);
    setProductId(null);
  }

  const submit = (event) =>{
      const data = {
        name : name,
        description : description,
        start : moment(start).format("YYYY-MM-DD"),
        end : moment(end).format("YYYY-MM-DD"),
        product_id : product_id,
        company_id : company_id
      }

    axios.post(process.env.REACT_APP_API_URI+"/promotions/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
      setLoading(false);
    })
  }
  const edit = (event) =>{
    const data = {
        name : name,
        description : description,
        start : moment(start).format("YYYY-MM-DD"),
        end : moment(end).format("YYYY-MM-DD"),
        product_id : product_id,
        company_id : company_id
    } 
    axios.put(process.env.REACT_APP_API_URI+`/promotions/${employee.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenEmployeeModal(false);
      refresh();
    }).catch(error=>{
      requestError({error : error})
    }).finally(done=>{
      setLoading(false);
    })
  }

  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const valuePicker = (event) =>{
    this.setState({[event.target.name] : event.target.value})
  }
  return (
    <>
    
      <Modal 
      title={`PRODUCT DETAILS ${action != `create`?`FOR #${employee.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenEmployeeModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{submit(); setLoading(true)}}>
            Submit
          </Button>
          :
          <Button key="submit" type="primary" loading={loading} onClick = {() =>{edit(); setLoading(true)}}>
            Save
          </Button>        
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      >
        <Form.Item
        label = "Promotion Name"
        rules={[
            { required: true, message: "Please input your name!" },
        ]}>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Promotion name" />
        </Form.Item>

        <Form.Item
        label = "Promotion Description"
        rules={[
            { required: true, message: "Please input your desciption!" },
        ]}>
          <Input.TextArea value={description} onChange={(event)=>setDescription(event.target.value)} name = "description" placeholder="Description" />
        </Form.Item>

        <Form.Item label="Promotion Starts" className='mb-2'>
            <DatePicker value={start} style={{width : "100%"}}    onChange={(value)=>setStart(value)} name = "start" placeholder="Promotion Start" />
        </Form.Item>
        
        <Form.Item label="Promotion Ends" className='m-2'>
            <DatePicker value={end} style={{width : "100%"}}    onChange={(value)=>setEnd(value)} name = "end" placeholder="Promotion End" />
        </Form.Item>

        <Form.Item
        label = "Select Company"
        rules={[
            { required: true, message: "Please selecte Company for the product!" },
        ]}
        >
          <Select
            showSearch
            onChange={(value)=>{
              setCompanyId(value);
              setProducts([]);
              setProductId(null)
              getCompanyProducts(value)
            }}
            value={company_id}
            placeholder="Select Company"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {
              companies.map(item =>
                <Option value={item.id}>{item.name}</Option>
                )
            }
          </Select>
        </Form.Item>
        
        <Form.Item
        label = "Select Product"
        rules={[
            { required: true, message: "Please selecte Company for the product!" },
        ]}
        >
          <Select
            showSearch
            onChange={(value)=>{
                setProductId(value);
            }}
            style={{
              // width: 200,
            }}
            value={product_id}
            placeholder="Select Product"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {
              products.map(item =>
                <Option value={item.id}>{item.name}</Option>
                )
            }
          </Select>
        </Form.Item>
      </Form>
      </Modal>
    </>
  );
};

export default App;