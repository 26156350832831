import {Table, Avatar, Typography, Tag, Button, Modal, Input, Form, Select, message, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/global';
import { EyeOutlined } from '@ant-design/icons';
import ModalAdd from "./orders";


const { Title } = Typography;
  
// table code start
const columns = [
  {
      title: "PRODUCT",
      dataIndex: "name",
      key: "name",
      width: "100%",
  }
];


const App = ({show, setOpenEmployeeModal, products}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  if(show != isModalVisible){
    setIsModalVisible(show)
  }
  
  return (
    <>
    
      <Modal 
      width={'450px'}
      title={`PROMOTION PRODUCTS` }
      visible={isModalVisible} 
      onOk={()=>setOpenEmployeeModal(false)} 
      onCancel={()=>setOpenEmployeeModal(false)}
      footer={[]}
      >
        <div className="table-responsive">
            <Table
            columns={columns}
            dataSource={products}
            pagination={true}
            className="ant-border-space"
            />
        </div>
      </Modal>
    </>
  );
};

export default App;